import { Box, useColorModeValue} from '@chakra-ui/react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Main from './main/Main';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import GuideLines from './components/guidelines/Guidelines';
import CopyRightPolicy from './components/copyrightPolicy/CopyRightPolicy';
import UserAgreement from './components/userAgreement/UserAgreement';
import SecurityPolicy from './components/securityPolicy/SecurityPolicy';
import TermsOfUse from './components/termsOfUse/TermsOfUse';
import SubscriptionAgreement from './components/subAgreement/SubscriptionAgreement';
import AdvertisingPolicy from './components/adPolicy/AdvertisingPolicy';
import AdvertisingDisclaimer from './components/adDisclaimer/AdvertisingDisclaimer';

function App() {
  const bg = useColorModeValue('#F8FAFF', '#11131A')
  const AppLayout = ({ children }) => (
    <>
      <Box
        px={{ base: '20px', md: '50px', lg: '140px' }}
        width="100%"
        bg={bg}
        pt={6}
        pb={10}
      >
        <Navbar />
        {children}
        <Footer />
      </Box>
    </>
  )
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout><Main /></AppLayout>} />
          <Route path="/privacy-policy" element={<AppLayout><PrivacyPolicy /></AppLayout>} />
          <Route path="/ad-policy" element={<AppLayout><AdvertisingPolicy /></AppLayout>} />
          <Route path="/ad-disclaimer" element={<AppLayout><AdvertisingDisclaimer /></AppLayout>} />
          <Route path="/copyright-policy" element={<AppLayout><CopyRightPolicy /></AppLayout>} />
          <Route path="/security-policy" element={<AppLayout><SecurityPolicy /></AppLayout>} />
          <Route path="/user-agreement" element={<AppLayout><UserAgreement /></AppLayout>} />
          <Route path="/sub-agreement" element={<AppLayout><SubscriptionAgreement /></AppLayout>} />
          <Route path="/terms" element={<AppLayout><TermsOfUse /></AppLayout>} />
          <Route path="/guidelines" element={<AppLayout><GuideLines /></AppLayout>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
