import React from 'react';
import Phone from '../../utils/images/Bottom.png';
import Phone1 from '../../utils/images/Bottom1.png';
import Phone2 from '../../utils/images/Bottom2.png';
import APPLESTORE from '../../utils/images/AppleStore.png';
import PLAYSTORE from '../../utils/images/PlayStore.png';
import {
  Box,
  Flex,
  Link,
  useColorModeValue,
  Image,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import {
  BODY_MEDIUM,
  BODY_SMALL,
  HEADLINE_SMALL,
  HEADLINE_XXSMALL,
} from '../../utils/fonts/fonts';

const Bottom = () => {

  const textSize = useBreakpointValue({
    base: BODY_SMALL,
    md: BODY_MEDIUM,
    lg: BODY_MEDIUM,
  });
  const buttonSize = useBreakpointValue({
    base: { width: '120px', height: '100%' },
    md: { width: '100%', height: '100%' },

  });
  const bottomImage=useBreakpointValue({base:Phone2, md:Phone1, lg:Phone})
  const textColor = useColorModeValue( 'dark.onSurface','dark.onSurface');

  return (
<Box>
  <Box
    my={10}
    rounded="24"
    bgGradient={'linear(to-r,#0042A0,#930017)'}
    pl={{ base: '0px', lg: '70px' }}
    mt={{base:8,md:12,lg:16}}

    color={textColor}
  >
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      justify={{ base: 'center', lg: 'space-between' }}
    >
        <Box
        px={{ base: '20px', md:'40px', lg: '28px' }}
        py={{ base: '16px', lg: '40px' }}
        width={{ base: '200', md: '300', lg: '450px' }}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        <Text sx={HEADLINE_SMALL} >
          Get Started Today!
        </Text>

        <Text sx={textSize} pt={{ base: '16px', lg: '24px' }} >
          Download Swipe Lover on Google Play or App Store and experience the
          next level of secure, interactive social networking.
        </Text>

        <Text sx={HEADLINE_XXSMALL} pt={{ base: '16px', lg: '24px' }} >
          Join now and be part of a vibrant and authentic community!
        </Text>
        <Text
              sx={HEADLINE_XXSMALL}
              pt={{ base: '16px', lg: '24px' }}
            >
              Will be available soon on:
            </Text>

        <Flex
          pt={{ base: '6px', md: '8px', lg: '10px' }}
          justify={{ base: 'center', lg: 'start' }}
          gap={{base:'8px', md:"16px"}}
        >
          <Link _hover={{ opacity: 0.8 }} bg={null}>
            <Image src={APPLESTORE}  alt='Apple Store Button' sx={buttonSize}/>
          </Link>
          <Link _hover={{ opacity: 0.8 }} bg={null}>
            <Image src={PLAYSTORE} alt='Play Store Button' sx={buttonSize}/>
          </Link>
        </Flex>
      </Box>

      <Box
        flex="1"
        display="flex"
        justifyContent={{base:'center', lg:"end"}}
        alignItems="center"
      >
        <Image
          roundedBottom={{ base: '24', md: '0' }} 
          height={{ base: 'auto', lg: '100%' }} 
          width={{ base: '100%',md:'auto', lg: 'auto' }} 
          objectFit="cover" 
          src={bottomImage}
          alt='Swipe Lover'
        />
      </Box>
    </Flex>
  </Box>
</Box>
  );
};

export default Bottom;