import { Box, Divider, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL } from '../../utils/fonts/fonts';

const AdvertisingDisclaimer = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });
    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Advertising Disclaimer
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 4, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    At  Swipe Lover, we aim to provide transparency and clarity in our advertising
                    practices. This Advertising Disclaimer explains the limitations of claims made in
                    Swipe Lover advertisements, the role of influencers, the availability of features, and
                    the expectations for individual results. By engaging with Swipe Lover advertising,
                    you acknowledge and agree to the following terms:
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. No Guarantee of Results
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Personal Outcomes May Vary:</b> Swipe Lover does not guarantee specific
                            outcomes,   relationship   success,   or   matches.   The   platform   is   designed   to
                            facilitate   connections,   but   the   success   of   those   connections   depends   on
                            individual interactions, compatibility, and engagement.</li>
                        <li><b>Testimonials   and   Success   Stories:</b>   Any   testimonials   or   success   stories
                            featured in Swipe Lover advertisements represent the experiences of specific
                            individuals.   These   stories   are   for   illustrative   purposes   only   and   are   not
                            necessarily reflective of typical user experiences.</li>
                    </ul>
                </Box>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Paid Influencer and Partner Content
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Influencer Compensation:</b> Swipe Lover partners with influencers and other
                            third-party   content   creators   who   may   be   compensated   for   promoting   the
                            platform. Such endorsements are labeled as sponsored or paid content in
                            compliance with advertising guidelines.</li>
                        <li><b>Influencer   Opinions:</b>   The   views,   claims,   or   endorsements   shared   by
                            influencers   are   their   personal   opinions   and   do   not   represent   the   official
                            position of Swipe Lover. Influencers may share their individual experiences or
                            perspectives, which may not align with the general user experience on the app.</li>
                    </ul>
                </Box>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Feature Availability and Limitations
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Subscription-Based Features:</b> Swipe Lover offers both free and premium
                            (paid) subscription plans. Certain features highlighted in advertisements may
                            only be available to users on premium plans, such as Swipe Lover Plus or Pro.
                            Free plan users may not have access to all features advertised.</li>
                        <li><b>Geographic and Demographic Limitations:</b> Availability of some features
                            may vary by geographic region or demographic restrictions. For example, age-
                            restricted features or location-based recommendations may not be accessible
                            to all users based on their profile and location.</li>
                        <li><b>Right to Modify Features:</b> Swipe Lover reserves the right to update, modify,
                            or remove features at its discretion without prior notice. Advertised features
                            may be updated as part of ongoing app improvements or adjustments.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Individual Experience May Vary
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Unique User Interactions:</b> Users’ interactions and experiences on Swipe
                            Lover are unique and based on personal preferences, engagement, and choices made within the app. As a result, outcomes related to meeting new people,
                            forming   connections,   or   user   engagement   may   differ   widely   from   those
                            highlighted in advertisements.</li>
                        <li><b>No Endorsement of Third-Party Claims:</b> Swipe Lover does not endorse
                            specific claims made by third parties, including influencers, partners, or other
                            users, regarding the expected experience on the app. Any reliance on third-
                            party claims is at the user’s discretion.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Third-Party Content and Advertising
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Third-Party   Advertisements:</b>   Swipe   Lover   may   display   third-party
                            advertisements on its platform or in app-related channels. The appearance of
                            third-party   ads   does   not   constitute   an   endorsement,   affiliation,   or
                            recommendation by Swipe Lover.</li>
                        <li><b>Accuracy and Legality of Third-Party Ads:</b> Swipe Lover is not responsible
                            for the accuracy, legality, or content of third-party advertisements. Users are
                            encouraged   to   exercise   caution   and   review   third-party   content   or   offers
                            independently.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Refund Policy and No Warranties
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>No Refunds on Premium Features:</b> Advertised premium features are subject
                            to Swipe Lover’s refund policy, which is typically non-refundable. Users are
                            advised   to   review   subscription   details   and   terms   before   purchasing   any
                            premium features.</li>
                        <li><b>No Warranties or Guarantees:</b> Swipe Lover provides the app and services
                            on   an   “as-is”   basis   and   makes   no   guarantees   regarding   compatibility,
                            satisfaction, or suitability for any specific purpose. Advertisements do not
                            imply warranties of any kind, express or implied.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Promotional Content and Sweepstakes
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Promotional Offers and Incentives:</b> From time to time, Swipe Lover may
                            offer   promotions,   discounts,   or   incentives   through   advertisements.   These
                            offers are limited to specific campaigns and may expire after a designated
                            period. Eligibility requirements and terms will be detailed in each promotion’s
                            terms and conditions.</li>
                        <li><b>Sweepstakes   and   Contests:</b>   Any   advertised   contests,   sweepstakes,   or
                            challenges are governed by separate terms and conditions, which participants
                            are required to review. Swipe Lover reserves the right to modify or cancel
                            promotional events at its discretion.</li>
                    </ul>
                </Box>
                <Divider  borderWidth={'1px'} borderColor={textColor}/>
                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    For any questions or concerns about Swipe Lover’s advertising practices, please refer
                    to our Terms of Service and Privacy Policy, or contact us at support@swipelover.com
                    or founder@digitalbikana.com. Your understanding and acknowledgment of these
                    disclaimers help us maintain transparency and set clear expectations.
                </Text>

            </Box>

        </>
    );
};

export default AdvertisingDisclaimer;