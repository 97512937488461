import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const SecurityPolicy = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Safety & Security Policy
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    At <b>Swipe Lover,</b> the safety and security of our users are top priorities. This Safety
                    and Security Policy outlines our commitment to creating a secure environment for all
                    users, detailing the practices, technologies, and safeguards we use to protect user data
                    and promote respectful interactions. By using Swipe Lover, you agree to follow these
                    guidelines to ensure a safe community for everyone.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. User Safety Responsibilities
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    1.1. Respectful Interactions
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Users are expected to engage respectfully with others. Harassment, threats, or
                    abusive language are strictly prohibited. Follow the Community Guidelines to
                    maintain a positive and supportive environment.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    1.2. Protect Your Personal Information
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    To safeguard your privacy, avoid sharing sensitive information, such as your
                    address,   financial   details,   or   other   personal   data,   with   other   users.   Keep
                    interactions within the app until you feel safe sharing more information.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    1.3. Awareness of Potential Risks
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Users should be aware that interactions, both online and offline, come with
                    inherent risks. We encourage users to exercise caution, especially when meeting
                    in person, and to arrange initial meetings in public, well-populated areas.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Prohibited Behaviors and Content
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.1. No Harassment or Threats
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Threatening, harassing, or bullying other users is strictly prohibited. Report any
                    such behavior immediately. Swipe Lover enforces a zero-tolerance policy for
                    abusive interactions.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.2. No Blackmail, Extortion, or Exploitative Behavior
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Blackmail,   extortion,   or   any   attempt   to   exploit   another   user’s   personal
                    information   is   not   allowed.   Violations   may   result   in   immediate   account
                    suspension and reporting to authorities where appropriate.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.3. No Nudity or Sexually Explicit Content
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover prohibits the sharing of nudity, sexually explicit, or pornographic
                    content. Users violating this policy will face account suspension and potential
                    legal consequences.
                </Text>

                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Verification and Profile Safety
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Real-Time Image and Video Verification
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    To ensure a safe environment, Swipe Lover requires users to complete real-time
                    image or video verification during account creation. This helps confirm that
                    users meet age requirements and accurately represent themselves.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Government ID Verification
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    For enhanced safety, users may be required to submit a government-issued ID.
                    Our   third-party   verification   provider   matches   the   ID   photo   with   real-time
                    verification   images   to   ensure   authenticity.   This   measure   helps   prevent
                    impersonation and improves overall platform security.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.3. AI-Based Moderation and Monitoring
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover uses artificial intelligence (AI) to monitor messages, live streams,
                    and other interactions for inappropriate content, such as explicit language or
                    nudity. While AI is not perfect, it helps detect and flag content for review by
                    our moderation team.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Data Protection and Privacy
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. Data Collection and Use
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover collects data for verification, safety, and compliance purposes, as
                    detailed in our Privacy Policy. This includes real-time images, government ID
                    data, and usage data to enhance security and ensure a safe user experience.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Encryption and Secure Storage
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    All user data, including personal details, messages, and verification images, is
                    encrypted and stored securely. Only authorized personnel and trusted third-
                    party providers have access to this data.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.3. Data Retention
                </Text>

                <Text sx={text3Size} mb={'10px'}>
                    Verification data is retained as long as necessary to fulfill its purpose and
                    comply with legal obligations. Live stream data is stored for a maximum of 30
                    days,   after   which   it   is   deleted   permanently   unless   needed   for   security
                    investigations.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. User Reporting Tools
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. Reporting Inappropriate Behavior
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   provides   reporting   tools   for   users   to   report   inappropriate   or
                    suspicious activity, including harassment, abuse, nudity, and threats. Reported
                    content is reviewed by our moderation team, and action is taken promptly.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2.  How to Report Violations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    To report a violation, use the report function on the user’s profile or within the
                    chat.   You   may   also   contact   our   support   team   directly   at
                    support@swipelover.com or founder@digitalbikana.com. Reports are reviewed,
                    and users are notified of any actions taken where appropriate.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.3. Confidentiality of Reports
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Reports are handled with strict confidentiality. User identities will not be
                    disclosed without consent, unless required by law or to protect user safety.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Incident Response and Moderation
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Immediate Actions on Violations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover takes swift action against violations of our safety policies. This
                    may include issuing warnings, temporary suspensions, or permanently banning
                    accounts involved in serious breaches.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. Escalation to Authorities
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    For severe violations involving criminal activity (e.g., blackmail, exploitation,
                    or threats), Swipe Lover may involve law enforcement to ensure appropriate
                    action is taken.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.3.  Review and Appeal Process
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users who believe they have been wrongly suspended or banned may appeal by
                    contacting Swipe Lover’s support team. All appeals are reviewed thoroughly,
                    but the final decision rests with Swipe Lover to uphold platform safety.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Meeting in Person and Offline Safety
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. Tips for Safe Meetings
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    When arranging in-person meetings with someone you’ve met on Swipe Lover,
                    follow these safety tips:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Meet in a public place.</li>
                        <li>Inform a friend or family member of your plans.</li>
                        <li>Arrange your own transportation to and from the meeting location.</li>
                        <li>Trust your instincts, and don’t feel pressured to continue if you feel
                            uncomfortable.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2 Disclaimer for Offline Interactions
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover does not guarantee user conduct or assume responsibility for
                    interactions that occur offline. Users are solely responsible for their personal
                    safety when choosing to meet others in person.
                </Text>


                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Technical Safeguards and AI Limitations
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.1. AI-Based Monitoring
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover employs AI to detect and moderate potentially harmful content,
                    including   offensive   language,   nudity,   and   explicit   content.   However,   AI
                    technology has limitations and may not detect all instances of inappropriate
                    behavior. Users should report any missed violations they encounter.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.2. Limitations of AI and Third-Party Providers
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    While we strive for accuracy, AI-based moderation and third-party verification
                    services may produce occasional errors. Swipe Lover disclaims liability for any
                    harm or issues arising from these technological limitations.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.3. Protection Against Hacking and Unauthorized Access
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   uses   robust   security   measures,   including   encryption,   secure
                    storage, and regular system updates, to protect against unauthorized access,
                    hacking, and data breaches. Users are encouraged to secure their accounts by
                    using strong passwords and avoiding public Wi-Fi when accessing the app.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    9. Security Awareness and User Education
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.1. Educating Users on Security Best Practices
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover provides users with resources and guidelines on how to stay safe
                    on the platform, including avoiding scams, recognizing phishing attempts, and
                    protecting personal information.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.2. Notifications of Security Risks
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users may receive notifications about potential security risks or updates on best
                    practices within the app. These notifications are designed to help users stay
                    informed and take proactive steps to protect their personal information.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.3. Responsibility for Account Security
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users are responsible for securing their own accounts. Swipe Lover will not ask
                    for sensitive information, such as passwords or financial details, outside the
                    app, and users should report any suspicious requests immediately.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    10. Changes to This Safety and Security Policy
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.1. Policy Updates
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   may   update   this   Safety   and   Security   Policy   periodically   to
                    enhance user safety, reflect new practices, or comply with legal requirements.
                    Users will be notified of significant updates via in-app notifications or email.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.2. Effective Date
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    The effective date of this Safety and Security Policy is noted at the top of the
                    document. Continued use of Swipe Lover after updates indicates acceptance of
                    the revised policy.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    11. Contact Information and Support
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.1. Reporting Safety Concerns
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    For safety concerns, questions, or to report a violation of this policy, please
                    contact   our   support   team   at  support@swipelover.com   or
                    founder@digitalbikana.com. Our team is available to assist with any issues that
                    may arise.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.2. Grievance Redressal Officer
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover has appointed a Grievance Redressal Officer to handle complaints
                    and address any safety-related concerns. Contact information for the Grievance
                    Redressal Officer is available on our website.
                </Text>

                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    <b>Thank you for helping make Swipe Lover a safe and respectful platform for
                        everyone!</b>
                </Text>

            </Box>

        </>
    );
};

export default SecurityPolicy;