export const DISPLAY_LARGE = {
    fontFamily: 'Poppins',
    fontSize: '57px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '64px', /* 112.281% */
    letterSpacing: '-0.25px',
};

export const DISPLAY_MEDIUM = {
    fontFamily: "Poppins",
    fontSize: "var(--Display-Medium-Size, 45px)",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "var(--Display-Medium-Line-Height, 52px)", // 115.556%
    letterSpacing: "var(--Display-Medium-Tracking, 0px)",
};
export const DISPLAY_SMALL = {
    fontFamily: 'Poppins',
    fontSize: 'var(--Display-Small-Size, 36px)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--Display-Small-Line-Height, 44px)', /* 122.222% */
    letterSpacing: 'var(--Display-Medium-Tracking, 0px)',
};


export const HEADLINE_LARGE={
    fontFamily: "Poppins",
    fontSize: "var(--Headline-Large-Size, 32px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Headline-Large-Line-Height, 40px)", // 125%
    letterSpacing: "var(--Headline-Large-Tracking, 0px)"
}

export const HEADLINE_MEDIUM={
    fontFamily: "Poppins",  
    fontSize: "var(--Headline-Medium-Size, 28px)",  
    fontStyle: "normal",  
    fontWeight: 700,  
    lineHeight: "var(--Headline-Medium-Line-Height, 36px)",  
    letterSpacing: "var(--Headline-Small-Tracking, 0px)"  
}

export const HEADLINE_SMALL = {
    fontFamily: "Poppins",  
    fontSize: "var(--Headline-Small-Size, 24px)",  
    fontStyle: "normal",  
    fontWeight: 700,  
    lineHeight: "var(--Headline-Small-Line-Height, 32px)",  
    letterSpacing: "var(--Headline-Small-Tracking, 0px)"  
};

export const HEADLINE_XSMALL = {
    fontFamily: "Poppins",  
    fontSize: "20px",  
    fontStyle: "normal",  
    fontWeight: 700,  
    lineHeight: "24px"  
};

export const HEADLINE_XXSMALL = {
    fontFamily: "Poppins",  
    fontSize: "16px",  
    fontStyle: "normal",  
    fontWeight: 700,  
    lineHeight: "24px"  
};

export const TITLE_LARGE = {
    fontFamily: "Poppins",  
    fontSize: "var(--Title-Large-Size, 22px)",  
    fontStyle: "normal",  
    fontWeight: 400,  
    lineHeight: "var(--Title-Large-Line-Height, 28px)",  
    letterSpacing: "var(--Title-Large-Tracking, 0px)"  
};

export const TITLE_MEDIUM = {
    fontFamily: "Poppins",  
    fontSize: "var(--Title-Medium-Size, 16px)",  
    fontStyle: "normal",  
    fontWeight: 500,  
    lineHeight: "var(--Title-Medium-Line-Height, 24px)",  
    letterSpacing: "var(--Title-Medium-Tracking, 0.15px)"  
};

export const TITLE_SMALL = {
    fontFamily: "Poppins",  
    fontSize: "var(--Title-Small-Size, 14px)",  
    fontStyle: "normal",  
    fontWeight: 500,  
    lineHeight: "var(--Title-Small-Line-Height, 20px)",  
    letterSpacing: "var(--Title-Small-Tracking, 0.1px)"  
};

export const LABEL_LARGE = {
    fontFamily: "Poppins",  
    fontSize: "var(--Label-Large-Size, 14px)",  
    fontStyle: "normal",  
    fontWeight: 500,  
    lineHeight: "var(--Label-Large-Line-Height, 20px)",  
    letterSpacing: "var(--Label-Large-Tracking, 0.1px)"  
};

export const LABEL_MEDIUM = {
    fontFamily: "Poppins",  
    fontSize: "var(--Label-Medium-Size, 12px)",  
    fontStyle: "normal",  
    fontWeight: 500,  
    lineHeight: "var(--Label-Medium-Line-Height, 16px)",  
    letterSpacing: "var(--Label-Medium-Tracking, 0.5px)"  
};

export const LABEL_SMALL = {
    fontFamily: "Poppins",  
    fontSize: "var(--Label-Small-Size, 11px)",  
    fontStyle: "normal",  
    fontWeight: 500,  
    lineHeight: "var(--Label-Small-Line-Height, 16px)",  
    letterSpacing: "var(--Label-Small-Tracking, 0.5px)"  
};

export const BODY_LARGE = {
    fontFamily: "Poppins",  
    fontSize: "var(--Body-Large-Size, 16px)",  
    fontStyle: "normal",  
    fontWeight: 400,  
    lineHeight: "var(--Body-Large-Line-Height, 24px)",  
    letterSpacing: "var(--Body-Large-Tracking, 0.5px)"  
};

export const BODY_MEDIUM={
    fontFamily: 'Poppins',
    fontSize: 'var(--Body-Medium-Size, 14px)',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'var(--Body-Medium-Line-Height, 20px)', /* 142.857% */
    letterSpacing: 'var(--Body-Medium-Tracking, 0.25px)',
};

export const BODY_SMALL = {
    fontFamily: "Poppins",  
    fontSize: "var(--Body-Small-Size, 12px",  
    fontStyle: "normal",  
    fontWeight: 400,  
    lineHeight: "var(--Body-Small-Line-Height, 16px)",  
    letterSpacing: "var(--Body-Small-Tracking, 0.4px)"  
};
