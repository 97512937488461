import React from 'react';
import Phone from '../../utils/images/Signup.png';
import APPLESTORE from '../../utils/images/AppleStore.png';
import PLAYSTORE from '../../utils/images/PlayStore.png';
import Rectangle from '../../utils/images/Rectangle1.png';
import {
  Box,
  Flex,
  Image,
  useBreakpointValue,
  Text,
  Link,
} from '@chakra-ui/react';
import {
  BODY_MEDIUM,
  BODY_SMALL,
  DISPLAY_MEDIUM,
  DISPLAY_SMALL,
  HEADLINE_SMALL,
  HEADLINE_XXSMALL,
} from '../../utils/fonts/fonts';

const Top = () => {
  const imageSize = useBreakpointValue({
    base: { width: '121.606px', height: '262.292px' },
    md: { width: '162.141px', height: '349.722px' },
    lg: { width: '279.554px', height: '100%' },

  });
  const buttonSize = useBreakpointValue({
    base: { width: '120px', height: '100%' },
    md: { width: '100%', height: '100%' },

  });

  const textSize = useBreakpointValue({
    base: HEADLINE_SMALL,
    md: DISPLAY_SMALL,
    lg: DISPLAY_MEDIUM,
  });

  const text2Size = useBreakpointValue({
    base: BODY_SMALL,
    md: BODY_MEDIUM,
    lg: BODY_MEDIUM,
  });

  const textColor = 'dark.onSurface';

  return (
    <Box>
      <Box
        rounded="24"
        // bgPos={{ base: 'top', md: 'top', lg: 'unset' }}
        bgImage={{ base: Rectangle, md: Rectangle, xl: Rectangle }}
        color={textColor}
        bgRepeat={'no-repeat'}
        bgSize="100% 90%"
        mt={{base:4,md:8,lg:12}}
        px={{ base: '10px', md: '40px', lg: '28px' }}
      >
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify={{ base: 'center', lg: 'space-around' }}
          align="center"
        >
          <Box
            width={{ base: '200', md: '300', lg: '650px' }}
            textAlign={{ base: 'center', lg: 'left' }}
            pl={{ base: '0px', md: '0px', lg: '40px' }}
            pt={{ base: '10px', md: '20px', lg: '-10px' }}
            mt={{ base: '10px', md: '20px', lg: '-150px' }}
          >
            <Text
              sx={textSize}
            >
              Welcome to Swipe Lover!
              Connect, Engage, and Discover Like Never Before
            </Text>

            <Text
              sx={text2Size}
              pt={{ base: '16px', lg: '24px' }}
            >
              Swipe Lover is a secure, AI-powered app where you can meet authentic connections and enjoy live interactions.
            </Text>
            <Text
              sx={HEADLINE_XXSMALL}
              pt={{ base: '16px', lg: '24px' }}
            >
              Will be available soon on:
            </Text>

            <Flex
              pt={{ base: '6px', md: '8px', lg: '10px' }}
              justify={{ base: 'center', lg: 'flex-start' }}
              gap={{base:"8px", md: "16px"}}
            >
              <Link
                _hover={{ opacity: 0.8 }}
                href='#'
                bg={null}
                >
                <Image
                  src={APPLESTORE}
                  sx={buttonSize}
                  mx={'0'}
                  alt='App Store Button'
                  />
              </Link>
              <Link
                _hover={{ opacity: 0.8 }}
                bg={null}
                href='#'
                >
                <Image
                  src={PLAYSTORE}
                  mx={'0'}
                  sx={buttonSize}
                  alt='Play Store Button'
                />
              </Link>
            </Flex>
          </Box>

          <Box
            pt={{ base: '24px', md: '32px', lg: '65px' }}
            mr={{ base: '0px', md: '0px', lg: '44px' }}
          >
            <Image
              sx={imageSize}
              src={Phone}
              alt='Swipe Lover'
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Top;
