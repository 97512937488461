import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const AdvertisingPolicy = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Advertising Policy
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    At <b>Swipe Lover</b>, we are committed to providing accurate and transparent information
                    about our app and services. This False Advertising Policy establishes our standards
                    for truthful advertising, the types of misleading claims we prohibit, and the steps users
                    can take to report false advertising. By interacting with Swipe Lover content, you
                    acknowledge and support our commitment to integrity in advertising.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Policy Objective
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    The goal of this False Advertising Policy is to ensure that all advertisements,
                    promotions, and marketing content for Swipe Lover are accurate, clear, and non-
                    deceptive. Swipe Lover is committed to complying with applicable advertising
                    laws and protecting users from potentially misleading claims.
                </Text>

                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Prohibited Advertising Practices
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover prohibits the following types of false or misleading advertising
                    practices:
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.1. Misleading Claims About Results
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover does not guarantee specific outcomes, matches, or relationships
                    through our app. Advertisements must not imply or promise results, such as
                    guaranteed matches, relationship success, or unrealistic outcomes.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.2. False Endorsements and Testimonials
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover ensures that all testimonials, endorsements, and reviews featured
                    in our advertising are genuine. We do not support the use of fabricated or
                    exaggerated endorsements and require influencers to reflect honest opinions
                    based on their experience with the app.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.3. Misrepresentation of Features or Capabilities
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    All   advertised   features,   benefits,   and   capabilities   of   Swipe   Lover   must
                    accurately reflect the app's current functionality. Advertisements should not
                    imply the availability of services or features that are not accessible to users,
                    especially if they are limited to specific subscription plans or geographic
                    locations.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.4. Exaggeration of Safety and Security Measures
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    While Swipe Lover prioritizes user safety, we do not claim that the app can
                    fully guarantee security or prevent all potential risks. Advertising should not
                    exaggerate our ability to protect users from harm but should highlight our
                    genuine safety features and user guidelines.
                </Text>


                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Standards for Influencer and Affiliate Marketing
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Influencer Disclosures
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Influencers and affiliates promoting Swipe Lover must clearly disclose their
                    material connection to the company, such as using hashtags like #ad or
                    #sponsored. Swipe Lover requires that all third-party endorsements are honest
                    and do not mislead followers about the influencer’s experience with the app.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Prohibited Claims by Affiliates and Partners
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Affiliates and influencers are prohibited from making any claims about Swipe
                    Lover that cannot be substantiated, including exaggerated claims about app
                    effectiveness, safety, or outcomes. Swipe Lover reserves the right to terminate
                    partnerships with affiliates who violate this policy.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Truthful Representation of Promotions and Offers
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. Limited-Time Offers and Discounts
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Advertised discounts, special offers, and promotions must clearly specify
                    eligibility   requirements,   expiration   dates,   and   any   conditions   that   apply.
                    Advertisements should not imply indefinite availability or create urgency based
                    on false time limitations.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Sweepstakes, Contests, and Incentives
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Any advertised contests, sweepstakes, or referral incentives will include official
                    rules, eligibility criteria, and prize details. Misleading claims about prize
                    amounts, eligibility, or the odds of winning are strictly prohibited.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Compliance with Advertising Laws
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. Adherence to Local and International Advertising Standards
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover complies with advertising regulations in each region where the
                    app is promoted, including but not limited to the Federal Trade Commission
                    (FTC)   guidelines   in   the   U.S.   and   equivalent   standards   globally.   Our
                    advertisements are designed to meet the truth-in-advertising requirements,
                    ensuring that claims are substantiated, clear, and transparent.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2. Content Review and Approval Process
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover conducts internal reviews of all promotional content to ensure it
                    aligns with this policy and complies with advertising standards. Swipe Lover
                    reserves the right to remove or modify any content that does not meet our
                    standards.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. User Reporting of False Advertising
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Reporting Mechanism
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users who encounter potentially misleading or false advertising related to
                    Swipe   Lover   are   encouraged   to   report   it   to   us   by   emailing
                    support@swipelover.com or founder@digitalbikana.com  with details of the
                    advertisement, including where it was seen and the specific claims in question.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. Review and Investigation
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover will review all reports of false or misleading advertising. If an ad
                    is found to violate our policies, corrective action will be taken, including
                    updating the ad or notifying third-party platforms to remove it.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.3. Protection Against Retaliation
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover is committed to fostering a transparent environment. Users who
                    report false advertising in good faith will not face any form of retaliation. We
                    value user feedback in upholding our advertising integrity.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Consequences of Policy Violations
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. Corrective Measures
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If Swipe Lover identifies false or misleading advertising content, immediate
                    corrective measures will be taken. This may involve retraction, clarification, or
                    modification of the advertisement.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2. Termination of Partnerships
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover may terminate agreements with affiliates, influencers, or partners
                    found to be in violation of this policy, especially if misleading content was
                    created intentionally or in repeated instances.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.3. Legal Compliance and Penalties
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover will cooperate with regulatory bodies if a policy violation results
                    in legal inquiry or penalties. We are committed to aligning with all advertising
                    standards to avoid any legal implications.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Policy Updates
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover reserves the right to modify this False Advertising Policy to reflect
                    evolving practices, regulatory changes, or updates to our advertising strategies. Users may be notified of significant changes via in-app notifications or updates
                    on our website.
                </Text>
                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    For any questions or concerns about this False Advertising Policy, please contact us at
                    support@swipelover.com or founder@digitalbikana.com.
                </Text>

            </Box>

        </>
    );
};

export default AdvertisingPolicy;