import React from 'react';
import Phone from '../../utils/images/Home.png';
import Rectangle from '../../utils/images/Rectangle2.png';
import {
    Box,
    Flex,
    Image,
    useBreakpointValue,
    Text,
} from '@chakra-ui/react';
import {
    BODY_MEDIUM,
    BODY_SMALL,
    HEADLINE_SMALL,
    HEADLINE_XXSMALL,
} from '../../utils/fonts/fonts';

const Mid = () => {
    const imageSize = useBreakpointValue({
        base: { width: '121.606px', height: '262.292px' },
        md: { width: '162.141px', height: '349.722px' },
        lg: { width: '279.554px', height: '602.969px' },
    });

    const textSize = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text2Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor ='dark.onSurface';

    return (
        <Box>
            <Box
        rounded="24"
        color={textColor}
        bgPos={{ base: 'top', md: 'top', lg: 'unset' }}
        bgImage={{ base: Rectangle, md: Rectangle,lg:Rectangle, xl: Rectangle }}
        bgRepeat={'no-repeat'}
        bgSize="100% 80%" 
        mt={6}
                px={{ base: '10px', md:'40px', lg: '28px' }} 
                >
                <Flex
                    direction={{ base: 'column', lg: 'row' }} 
                    justify={{ base: 'center', lg: 'space-around' }}
                    align="center"
                    >
                    <Box
                        pt={{ base: '24px', md: '32px', lg: '82.53px' }}
                        order={{ base: 2, md: 2, lg: 1 }}
                    >
                        <Image
                            sx={imageSize}
                            src={Phone}
                            alt='Swipe Lover'
                        />
                    </Box>

                    <Box
                        order={{ base: 1, md: 1, lg: 2 }}
                        pt={{ base: '24px', md: '32px', lg: '82.53px' }}
                        px={{ base: '12px', md: '16px', lg: '0px' }}
                        width={{ base: '200', md: '300', lg: '550px' }}
                        textAlign={{ base: 'left', lg: 'left' }} // Center-align text for smaller screens
                        alignSelf={'start'}

                    >
                        <Text
                            sx={textSize}
                        >
                            Secure & Authentic
                        </Text>

                        <Text
                            sx={text2Size}
                        >
                            Experience a secure and authentic environment with AI-powered verification and moderation, ensuring a safe space for all users.</Text>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Real-Time Fun
                        </Text>

                        <Text
                            sx={text2Size}
                        >
                            Enjoy real-time fun with live streams, interactive chats, and global leaderboards for a truly dynamic experience.</Text>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Personalized Matches            </Text>

                        <Text
                            sx={text2Size}
                        >
                            Discover nearby connections who share your interests, making every interaction meaningful and engaging.</Text>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Customizable Subscriptions     </Text>

                        <Text
                            sx={text2Size}
                        >
                            Enjoy access to features that fit your needs with flexible subscriptions and coin-based interactions.</Text>
                    </Box>

                </Flex>
            </Box>
        </Box>
    );
};

export default Mid;
