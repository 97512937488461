import {Text, useBreakpointValue } from '@chakra-ui/react';
import Top from '../components/top/Top';
import { HEADLINE_SMALL, DISPLAY_SMALL, HEADLINE_LARGE } from '../utils/fonts/fonts';
import Mid from '../components/mid/Mid';
import Features from '../components/features/Features';
import GettingStarted from '../components/gettingStarted/GettingStarted';
import Bottom from '../components/bottom/Bottom';
const Main=() =>{
  
  const textSize = useBreakpointValue({
    base: HEADLINE_SMALL,
    md: HEADLINE_LARGE,
    lg: DISPLAY_SMALL,
  });

  return (
    <>
      <Top/>
      <Text
      textAlign={'center'}
      sx={textSize}
      pt={{base:'82px', md:'92px', lg:'110px'}}
      pb={{base:'5px', md:'15px', lg:'20px'}}
      >
        Why Swipe Lover ?
      </Text>
      <Mid/>
      <Text
      textAlign={'center'}
      sx={textSize}
      pt={{base:'82px', md:'92px', lg:'100px'}}
      pb={{base:'5px', md:'15px', lg:'20px'}}

      >
        Key Features
      </Text>
      <Features/>
      <Text
      textAlign={'center'}
      sx={textSize}
      pt={{base:'82px', md:'92px', lg:'100px'}}
      pb={{base:'5px', md:'15px', lg:'20px'}}

      >
        Getting Started is Easy
        
      </Text>

    <GettingStarted/>
    <Bottom/>
    </>
  );
}

export default Main;
