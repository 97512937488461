import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const PrivacyPolicy = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Privacy Policy
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Welcome to <b>Swipe Lover!</b> This Privacy Policy outlines the types of information we
                    collect from users, how we process, use, share, and protect this information, and the
                    choices you have regarding your data. By using Swipe Lover, you agree to the
                    collection and use of information in accordance with this Privacy Policy.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Introduction
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover (“we,” “us,” or “our”) is committed to respecting and protecting
                    your privacy. This Privacy Policy is intended to inform you about how we collect,
                    use,   share,   and   protect   personal   data   when   you   use   our   mobile   application,
                    website, services, and features.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Scope of This Policy
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    This Privacy Policy applies to all users of Swipe Lover and covers:
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Information we collect and process.</li>
                        <li>The purposes for which we use the information.</li>
                        <li>Your rights regarding your personal data.</li>
                        <li>How you can manage and delete your information.</li>
                    </ul>
                </Box>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe   Lover   operates   under   the   jurisdiction   of   India,   and   we   comply   with
                    relevant data protection laws applicable to our global user base, including the
                    Information Technology Act, 2000, and the IT (Reasonable Security Practices and
                    Procedures and Sensitive Personal Data or Information) Rules, 2011.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Information We Collect
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    We collect information necessary for providing you with a safe and personalized
                    experience on Swipe Lover. The types of information we collect are as follows:
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Information You Provide Directly
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Account Information:</b> When you sign up, we collect your mobile number, or
                            Email address, profile name, date of birth, gender, location, and profile photo.</li>
                        <li><b>Profile Details:</b>  Optional   information,   including   your   relationship   status,
                            interests, hobbies, bio, and other profile details.</li>
                        <li><b>Verification   Data:</b>   We   collect   real-time   images   or   videos   for   profile
                            verification and age confirmation, as well as government-issued ID data if
                            required.</li>
                        <li><b>Financial Information:</b> When purchasing coins or subscriptions, payment
                            details are processed by our third-party payment processors and are not stored
                            on our servers.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Information We Collect Automatically
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Device Information:</b> We collect data about your device, including IP address,
                            operating system, device identifiers, and app version.</li>
                        <li><b>Usage Information:</b> We  track interactions with the  app, such as swipes,
                            messages,   live   streaming   participation,   and   engagement   with   premium
                            features.</li>
                        <li><b>Location Data:</b> We collect precise or approximate location information based
                            on your device settings to provide location-based features.</li>
                        <li><b>Cookies   and   Similar   Technologies:</b> We   use   cookies   and   tracking
                            technologies to enhance your experience, such as remembering preferences
                            and improving user interface functionality.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.3. Information From Third-Party Sources
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Social Media Login Data:</b> If   you   choose   to   sign   in   through   third-party
                            platforms   like   Facebook,   Google,   or   Apple,   we   collect   basic   profile
                            information as permitted by the respective platform.</li>
                        <li><b>ID Verification Services:</b> To ensure a safe user community, we use third-
                            party providers to verify government ID data, ensuring that the image on your
                            ID matches your profile picture.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. How We Use Your Information
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover processes your information for the following purposes:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. To Provide Core App Features
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Enable   account   creation,   profile   setup,   and   access   to   swiping,   direct messaging, random chat, and live streaming.</li>
                        <li>Facilitate interactions, such as sending hearts, commenting, and participating in leaderboards.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Verification and Safety
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Conduct real-time image and video verification and government ID checks to maintain a safe user environment.</li>
                        <li>Detect and prevent fraud, identity theft, and unauthorized use of the App.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.3. Personalization and User Experience
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Customize   user   experience   by   recommending   profiles   based   on   location, preferences, and engagement history.</li>
                        <li>Provide users with leaderboard rankings and profile viewing options.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.4. Premium Features and Transactions
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Process   subscriptions   and   coin   purchases,   providing   access   to   premium
                    features. We do not store payment data directly but work with secure third-
                    party processors.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.5. Advertising and Marketing
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Display in-app advertisements and partner with influencers for promotional
                    purposes. We do not share personal data for targeted advertising without your
                    consent.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.6. Compliance with Legal Obligations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Comply with applicable laws and regulations, respond to valid requests by
                    authorities, and ensure legal compliance in all operating regions.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. How We Share Your Information
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover shares user data only when necessary and as described below:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. With Service Providers
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We   work   with   third-party   providers   for   services   like   ID   verification,   data
                    storage, and payment processing. All providers are required to adhere to data
                    protection laws and standards.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2. For Legal Compliance and Safety
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We may disclose information to government authorities if required by law, for
                    the investigation of illegal activities, or to protect our rights and users’ safety.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.3. In Corporate Transactions
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If Swipe Lover undergoes a business transaction, such as a merger, acquisition,
                    or asset sale, user data may be transferred as part of the transaction, subject to
                    confidentiality agreements.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.4. With Your Consent
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We may share information with third parties for specific purposes with your
                    express consent, such as collaborating with social media influencers for app
                    promotions.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Your Rights and Choices
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Access and Update Your Information
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You can view and update your profile information through the App’s settings. It
                    is your responsibility to ensure that all data provided is accurate and up-to-date.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. Data Portability
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Where applicable, you may request a copy of your personal data in a structured,
                    machine-readable format. We will provide this data to you as required by
                    relevant data protection laws.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.3. Request Data Deletion
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You have the right to request the deletion of your account and all associated
                    data. To delete your data, navigate to the account settings and follow the steps
                    provided.   Once   your   account   is   deleted,   your   data   will   be   removed   or
                    anonymized,   except   where   retention   is   required   by   law   or   for   legitimate
                    business purposes (e.g., fraud prevention).
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.4. Opt-Out of Marketing Communications
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You may opt-out of receiving promotional or marketing communications at any
                    time by following the unsubscribe instructions in our messages. Please note that
                    we may still send non-promotional communications related to your account,
                    such as updates to our Terms of Service or Privacy Policy.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.5. Restrict or Object to Data Processing
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    In certain circumstances, you may request to restrict or object to the processing
                    of your data, especially if the processing is based on legitimate interests. We
                    will assess each request and inform you if any limitations apply.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.6. Manage Cookies and Tracking Technologies
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You   can   manage   your   cookie   preferences   directly   through   your   browser
                    settings. Note that disabling certain cookies may impact the functionality of
                    Swipe Lover.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Data Retention Policy
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. Retention Periods
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Profile and Usage Data: </b>We retain user data as long as your account is active
                            or as needed to provide the services.</li>
                        <li><b>Live Streaming Data: </b>  Data   from   live   streams,   including   comments   and
                            interactions, is retained for 30 days for safety and moderation purposes. After
                            this period, data will be deleted.</li>
                        <li><b>Inactive Accounts:</b> If an account remains inactive for one year, it may be
                            automatically deleted, including all associated data.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2 Legal and Compliance Retention
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We may retain certain information even after account deletion to comply with
                    legal obligations, prevent fraud, or enforce our policies. This data will be
                    retained   only   as   long   as   necessary   and   securely   deleted   when   no   longer
                    required.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Data Security
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.1. Security Measures
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We use industry-standard encryption and secure storage solutions to protect
                    your data. Access to personal data is restricted to authorized personnel and
                    third-party   providers   who   must   comply   with   stringent   data   protection
                    requirements.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.2. Limitations of Security
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    While we strive to protect your data, no system is entirely secure. We cannot
                    guarantee the absolute security of your information. You are responsible for
                    maintaining the confidentiality of your account credentials and for any activities
                    that occur under your account.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.3. Data Breach Response
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    In   the   event   of   a   data   breach,   we   will   notify   affected   users   and   relevant
                    authorities in compliance with applicable laws and regulations.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    9. International Data Transfers
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.1. Global Operations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   operates   globally,   and   your   data   may   be   transferred   to   and
                    processed in countries outside of your own. These countries may have different
                    data protection laws, but we take steps to ensure your data receives adequate
                    protection as required by Indian laws and international standards.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.2. Standard Contractual Clauses and Safeguards
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    When   transferring   personal   data   internationally,   we   implement   standard
                    contractual clauses, data protection agreements, or other measures to ensure
                    compliance with applicable laws and provide adequate data safeguards.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    10. Children’s Privacy
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.1. Minimum Age Requirement
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover is intended for users 18 years and older or of major age. We do
                    not knowingly collect or process data from individuals under 18 or minors. If
                    we discover that an underage user has provided us with personal information,
                    we will delete such data and terminate the account.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.2. Age Verification
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe  Lover uses real-time  image  and ID  verification methods  to prevent
                    underage users from accessing the App. If you believe a minor is using the
                    platform,   please   report   this   to   us   at  <a href='support@swipelover.com'>support@swipelover.com</a>   or
                    founder@digitalbikana.com.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    11. Third-Party Links and Services
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.1. Links to External Sites
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   may   contain   links   to   third-party   websites,   services,   or
                    applications. We do not control or endorse these external sites and are not
                    responsible   for   their   privacy   practices.   We   encourage   users   to   review   the
                    privacy policies of any third-party services they access.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.2. Third-Party Service Providers
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We   work   with   verified   third-party   providers   for   payment   processing,   ID
                    verification, and other services. These providers are bound by data protection
                    agreements, but we do not control their independent privacy policies. Any
                    issues regarding third-party providers should be directed to them.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    12. Push Notifications and Location-Based Services
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    12.1. Push Notifications
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    With   your   consent,   Swipe   Lover   may   send   you   notifications,   including
                    messages about app updates, new features, or special offers. You can disable
                    notifications in your device settings if you do not wish to receive them.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    12.2. Location-Based Services
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover may access your location to provide location-based features, such
                    as showing nearby profiles. You can disable location services through your
                    device settings, but this may limit certain features of the App.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    13. Cookies and Tracking Technologies
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    13.1. Use of Cookies
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We   use   cookies   and   similar   technologies   to   personalize   your   experience,
                    analyze traffic, and understand user behavior. Cookies enable functionalities
                    such as remembering preferences, maintaining session states, and tracking site
                    analytics.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    13.2. Types of Cookies
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Essential Cookies:</b> Necessary for the operation of the App and cannot be
                            disabled.</li>
                        <li><b>Functional Cookies:</b> Enable additional functionality, such as remembering
                            preferences, but are not essential.</li>
                        <li><b>Analytics and Performance Cookies:</b> Used for internal research on how the
                            App is used and to improve the user experience.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    13.3. Managing Cookies
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You can control or delete cookies through your browser settings. Disabling
                    cookies may impact your experience with Swipe Lover.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    14. Changes to This Privacy Policy
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    14.1. Policy Updates
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    We   may   update   this   Privacy   Policy   periodically   to   reflect   changes   in   our
                    practices, technology, or legal obligations. Users will be notified of material
                    changes via in-app notifications or email, as required by law.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    14.2. Effective Date
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    This   Privacy   Policy’s   effective   date   is   listed   at   the   top   of   the   document.
                    Continued use of Swipe Lover following an update indicates acceptance of the
                    revised policy.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    15. Contact Us:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    15.1. Questions and Complaints
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If you have questions about this Privacy Policy or wish to exercise your privacy
                    rights, please contact us at:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Email:</b> support@swipelover.com or founder@digitalbikana.com</li>
                        <li><b>Mailing Address:</b> Swipe Lover, Digital Bikana, Pareek House, behind
                            Kothari Hospital, Vaid Magharam Colony, Bikaner, Rajasthan, India
                            334001</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    15.2. Grievance Redressal Officer
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    As required under the Information Technology Act, 2000, Swipe Lover has
                    appointed   a   Grievance   Redressal   Officer   to   address   any   complaints   or
                    grievances regarding this Privacy Policy or data practices. Contact information
                    for the Grievance Redressal Officer is provided on our website.
                </Text>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>By using Swipe Lover, you acknowledge that you have read and understood this
                        Privacy   Policy   and   consent   to   the   collection,   use,   and   sharing   of   your
                        information as described.</b>
                </Text>

            </Box>

        </>
    );
};

export default PrivacyPolicy;