import React from 'react';
import {
    Box,
    Text,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react';
import {
    BODY_MEDIUM,
    BODY_SMALL,
    HEADLINE_SMALL,
    HEADLINE_XXSMALL,
} from '../../utils/fonts/fonts';

const GettingStarted = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text2Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });
    const textColor = 'dark.onSurface';
    const text=[
        {heading:'Sign Up & Verify', detail:'Quickly register with your mobile number or social account, then complete our secure AI-powered verification.'},
        {heading:'Set Preferences & Swipe', detail:'Choose your preferences, view profiles, and swipe to match!'},
        {heading:'Engage & Connect', detail:'Chat, stream, and meet new people in a community designed for safety and fun.'}
    ]

    return (
        <Box>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                textAlign="center"
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '40px' }}
                py={{ base: '16px', lg: '40px' }}
            >
                <Flex
                    gap={6}
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="start"
                >
                    {text.map((data)=>
                    <Box
                    key={data.heading}
                    w={{ base: '100%', md: '45%', lg: '30%' }}
                    >
                        <Text sx={textSize} >
                            {data.heading}
                        </Text>
                        <Text sx={text2Size} mt={2}>
                            {data.detail}
                        </Text>
                    </Box>
                    )}
                </Flex>
            </Box>
        </Box>
    );
};

export default GettingStarted;
