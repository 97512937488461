import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_XSMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const Guidelines = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                borderBottomRightRadius={'3xl'}
                textAlign="center"
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    sx={textSize}
                    textAlign={'center'}
                    color={'dark.onSurface'}
                >
                    Community Guidelines
                </Text>
            </Box>

            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Welcome to Swipe Lover! Our goal is to provide a safe, respectful, and enjoyable experience
                    for everyone in our community. These Community Guidelines outline the behavior expected
                    from all users and explain what actions are prohibited on our platform. By using Swipe Lover,
                    you agree to follow these guidelines and contribute to a positive community experience.
                </Text>

                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Respectful Behavior and No Harassment
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    1.1. Respect Others
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Treat all users with respect, kindness, and understanding. Disagreements may happen,
                    but maintain a respectful tone in all interactions, whether in direct messages, live
                    streaming, or random chat.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    1.2. No Harassment, Bullying, or Threats
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Harassment, bullying, hate speech, and any form of abusive or threatening language
                            are strictly prohibited. We do not tolerate behavior that intimidates, degrades, or
                            threatens others, whether on or off the platform.</li>
                        <li>This includes, but is not limited to, harassment based on race, ethnicity, religion,
                            gender, sexuality, nationality, or disability.</li>
                    </ul>
                </Box>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    2. Prohibited Content and Activities
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    2.1. No Nudity, Pornographic, or Sexually Explicit Content
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover strictly prohibits the sharing, uploading, or streaming of nudity,
                            pornography, or any sexually explicit content. This applies to profile pictures, direct
                            messages, live streams, and any other areas within the app</li>
                        <li>Violating this rule may lead to immediate account suspension or termination.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    2.2. No Violence, Hate Speech, or Dangerous Behavior
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Sharing or promoting violent, graphic, or hateful content is not allowed. This includes
                            content that incites violence, promotes harm, or encourages dangerous activities.</li>
                        <li>Do not glorify, encourage, or engage in acts of self-harm or substance abuse. Users
                            promoting or engaging in harmful activities will be subject to disciplinary actions.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    2.3. No Blackmail, Extortion, or Threats
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Blackmailing, threatening, or attempting to extort others is strictly prohibited. If you
                    encounter such behavior, please report it immediately. Swipe Lover works closely with
                    law enforcement to ensure that any criminal activities are addressed.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Personal Information and Privacy
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    3.1. Protect Your Privacy and Others’
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Do not share your personal information, such as your phone number, address, or
                            financial details, with others on the app. Keep all interactions within the app until you
                            feel safe and confident about the other person.</li>
                        <li>Never share another user’s private information without their consent. Violating this
                            rule is considered a serious offense and will lead to account suspension.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    3.2. Avoid External Links and Scams
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Do not share or click on suspicious links or try to solicit financial information from
                    others. Users involved in scams or phishing activities will face immediate account
                    termination.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    3.3. No Impersonation
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Creating fake profiles, impersonating another person, or using someone else’s identity
                    is not allowed. Each user must use their own identity and provide accurate information
                    during registration.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    4. Live Streaming and Chat Guidelines
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    4.1. Appropriate Behavior During Live Streams
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>When participating in or hosting a live stream, maintain a respectful and positive
                            environment. Nudity, explicit language, and offensive behavior are prohibited.</li>
                        <li>Users are encouraged to keep conversations friendly, inclusive, and engaging.
                            Violating these guidelines during live streams will lead to account suspension or ban.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    4.2. Random Chat Etiquette
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Be respectful when engaging in random chats. Keep conversations friendly and avoid
                            sharing personal contact details until you feel safe.</li>
                        <li>Refrain from pressuring other users to share personal information or engage in
                            activities they are uncomfortable with.</li>
                    </ul>
                </Box>

                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    5. Spam, Misleading, and Inappropriate Content
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    5.1. No Spam or Misleading Information
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Avoid spamming other users with repetitive messages, promotions, or irrelevant
                            content.   Users   engaging   in   spamming   will   face   penalties,   including   account
                            suspension.</li>
                        <li>Posting false or misleading information, including content meant to deceive or harm
                            others, is not allowed.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    5.2. Prohibited Advertising and Commercial Content
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover is a platform for personal connections, not for promoting businesses,
                    products, or services. Do not use the app to advertise or solicit purchases for external
                    goods or services.
                </Text>

                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    6. Reporting Violations and Consequences
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    6.1. Reporting Inappropriate Behavior
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover provides reporting tools to help keep the community safe. If you
                            encounter content or behavior that violates these guidelines, please report it using the
                            app’s reporting feature.</li>
                        <li>Our moderation team reviews all reports and takes appropriate action, including
                            warnings, suspensions, or bans, depending on the severity of the violation.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    6.2. Consequences for Violating Community Guidelines
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Violating these guidelines may result in disciplinary actions, including but not limited
                            to:
                            <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '20px' }}>
                                <li>Warnings: A warning issued to the user.</li>
                                <li>Temporary Suspension: Temporarily restricting access to the app.</li>
                                <li>Permanent Ban: Termination of the user’s account and all associated content.</li>
                            </ul>
                        </li>
                        <li>Serious violations may lead to legal action or referral to law enforcement, especially
                            in cases involving criminal activity, harassment, or threats.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    6.3. Appeal Process
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users who believe their account was wrongly suspended or banned can appeal by
                    contacting   our   support   team   at  Support@swipelover.com  or
                    Founder@digitalbikana.com . All appeals are reviewed thoroughly, but Swipe Lover
                    reserves the right to make final decisions on account status.
                </Text>

                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    7. Age Restrictions and Parental Guidance
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    7.1. Minimum Age Requirement
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover is for users aged 18 and above. Users found to be under 18 or minors
                            will be removed immediately.</li>
                        <li>OIf you encounter a user you believe to be underage, please report their profile so that
                            we can review it.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    7.2. Parental Guidance for Young Adults
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Parents and guardians are encouraged to speak with young adults about online safety
                    and responsible behavior when using social platforms like Swipe Lover.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    7. Age Restrictions and Parental Guidance
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    7.1. Minimum Age Requirement
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover is for users aged 18 and above. Users found to be under 18 or minors
                            will be removed immediately.</li>
                        <li>If you encounter a user you believe to be underage, please report their profile so that
                            we can review it.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    7.2. Parental Guidance for Young Adults
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Parents and guardians are encouraged to speak with young adults about online safety
                    and responsible behavior when using social platforms like Swipe Lover.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    8. Fair Use and Intellectual Property
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    8.1. Intellectual Property Respect
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Do not share, post, or display copyrighted material, such as music, images, or videos,
                            without permission from the copyright holder.</li>
                        <li>Users who infringe on intellectual property rights will be subject to account
                            suspension, and Swipe Lover will cooperate with legal authorities in cases of repeated
                            infringement.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    8.2. User-Generated Content
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Content shared on Swipe Lover, including profiles, images, and messages, must be
                    original or used with proper permission. Users are responsible for ensuring that any
                    content they post does not violate copyright or intellectual property laws.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    9. Protecting Our Community
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    9.1. Zero Tolerance for Exploitation and Abuse
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover does not tolerate any form of exploitation, including blackmail,
                            extortion, or abusive behavior.</li>
                        <li>We work with law enforcement and regulatory bodies to address any reports of
                            criminal activity or abuse within our platform.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    9.2. No Unauthorized Access or Tampering
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Do not attempt to hack, modify, or tamper with Swipe Lover’s systems or another
                    user’s account. Unauthorized access or interference is a violation of our Terms of
                    Service and may result in legal consequences.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    9.3. Reporting Technical Issues
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If you encounter technical issues or bugs, please report them to our support team.
                    Misusing or exploiting technical glitches for personal gain is prohibited.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    10. Changes to Community Guidelines
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    10.1. Updates to Guidelines
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover may update these Community Guidelines periodically. Users will be
                            notified of major changes via in-app notifications or email.</li>
                        <li>Continued use of the platform after updates indicates acceptance of the revised
                            guidelines.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'10px'}>
                    10.2. Contact Us
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If you have questions about these Community Guidelines, feel free to contact us at
                    Support@swipelover.com or Founder@digitalbikana.com .
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    <b>Thank you for helping make Swipe Lover a safe and respectful space for everyone!</b>
                </Text>
            </Box>
        </>
    );
};

export default Guidelines;