import React from 'react';
import Logo from '../../utils/images/Logo.svg'
import { Link as RouterLink } from 'react-router-dom';
import LogoDark from '../../utils/images/LogoDark.svg'

import {
  Box,
  Flex,
  Link,
  useColorModeValue,
  useColorMode,
  Image,
  Switch,
  useBreakpointValue,
  Divider,
} from '@chakra-ui/react';
import { BODY_MEDIUM, BODY_SMALL} from '../../utils/fonts/fonts';
import { FiSun } from 'react-icons/fi';
import { FaMoon } from 'react-icons/fa';


const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const imageSize = useBreakpointValue({ base: { width: '105px', height: '24px' }, md: { width: '169px', height: '32px' }, lg: { width: '181px', height: '36px' } });
  const textColor = useColorModeValue('light.onSurface', 'dark.onSurface')
  const boxColor = useColorModeValue('gray.300','gray.500');
  return (
    <>
      <Box
                bg={useColorModeValue('light.surfaceContainer', 'dark.surfaceContainer')} 
                rounded={{base:'12',md:'20', lg:'24'}}
                // my={6}
                px={{base:1,md:4}}
         zIndex="1">
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Flex h={{base:10, md:16}} alignItems={'center'}
          as={RouterLink}
          to={'/'}
          >
            <Image src={useColorModeValue(`${Logo}`, `${LogoDark}`)} sx={imageSize} alt='Swipe Lover Logo' />
          </Flex>

          <Flex alignItems={'center'} >
            <Link
              as={RouterLink}
              to={'/privacy-policy'}            
              mr={{base:2, md:4}}
              bg={null}
              _hover={{ opacity: 0.8 }}
              color={textColor}
              sx={{ sm: BODY_SMALL, md: BODY_MEDIUM, xl: BODY_MEDIUM }}
            >
              Privacy Policy
            </Link>

            <Divider orientation="vertical" height="20px" borderWidth="1px" borderColor="gray.400" />
            <Switch
              size="lg"
              alt='switch-mode'
              ml={2}
              isChecked={colorMode === "dark"}
              onChange={toggleColorMode}
              sx={{            
                ".chakra-switch__track": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bg: boxColor,
                  px: 1,
                },
                ".chakra-switch__thumb": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bg: useColorModeValue('white', 'gray.700'),
                  boxShadow: "sm",
                },
              }}
            >
              <Box
                as={FiSun}
                fontSize={15}
                color={textColor}
                cursor={'pointer'}
                position="absolute"
                left="2"
                bottom='1.5'
                zIndex="1"
              />
              <Box
                as={FaMoon}
                fontSize={15}
                cursor={'pointer'}
                color={textColor}
                position="absolute"
                bottom='1.5'
                right="2"
                zIndex="1"
              />      
              </Switch>
          </Flex>
        </Flex>

      </Box>
    </>
  );
};

export default Navbar;
