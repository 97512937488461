import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const TermsOfUse = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Terms Of Use
                </Text>
            </Box>
            <Box
                px={{ base: '20px', md: '30px', lg: '60px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 4, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Welcome to <b>Swipe Lover!</b> These Terms of Use (the “Terms”) govern your access to
                    and use of the Swipe Lover mobile application (the “App”) or website and all related
                    services, features, content, and applications. Please read these Terms carefully before
                    using the App or website.
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    These   Terms   create   a   binding   agreement   between  Swipe Lover (“we,” “us,” or
                    “our”) and you (“User,” “you,” or “your”). By downloading, accessing, or using the
                    App, you agree to comply with and be legally bound by these Terms. If you do not
                    agree with any part of these Terms, please do not use the App.
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    <b>Swipe Lover is launched and operates under Indian jurisdiction, and these
                        Terms are governed by the laws of India.</b> Any disputes will be exclusively resolved
                    in the courts of Bikaner, Rajasthan, India.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Acceptance of Terms and Eligibility
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    1.1. Acceptance of Terms
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    By registering, accessing, or using Swipe Lover, you acknowledge and agree to
                    be bound by these Terms, including any amendments. Continued use of the App
                    signifies acceptance of any updated Terms, which will be notified through in-
                    app notifications or website updates.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    1.2. Eligibility Requirements
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Age Requirement: </b>You must be at least 18 years old  or of major age  to use
                            Swipe  Lover. By creating an account, you represent  that  you meet  this  age
                            requirement.</li>
                        <li><b>Legal Capacity:</b> By agreeing to these Terms, you represent and warrant that
                            you have the legal right and capacity to enter into this agreement.</li>
                        <li><b>One Account Per User:</b>   You   may   create   only   one   account,   and   account
                            sharing is prohibited. Creating multiple accounts or impersonating others will
                            result in immediate suspension.</li>
                    </ul>
                </Box>

                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Account Registration and Security
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.1. Account Information and Verification
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>During   registration,   users   must   provide   accurate   and   truthful   information,
                            including   but   not   limited   to   real-time   image   capture,   government-issued   ID
                            verification,   and   a   valid   mobile   number.   Misrepresentation   of   identity   is
                            strictly prohibited.</li>
                        <li><b>Verification Process:</b>   We   utilize   real-time   image   and   video   verification   to
                            enhance   safety.   By   agreeing   to   these   Terms,   you   consent   to   verification
                            methods   and   the   storage   of   collected   data   in   accordance   with   our   Privacy
                            Policy.</li>

                    </ul>
                </Box>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.2. Account Security and Responsibility
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Users are responsible for maintaining the confidentiality of login details. You
                    agree to notify Swipe Lover immediately of any unauthorized account access or
                    breaches.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.3. Termination and Account Inactivity
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover reserves the right to suspend or terminate accounts for inactivity,
                    misrepresentation, or breaches of these Terms. Accounts inactive for one year
                    may be deleted automatically.
                </Text>

                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. User Responsibilities and Code of Conduct
                </Text>

                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Acceptable Use and Conduct
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Users   agree   to   conduct   themselves   respectfully   and   responsibly.   Prohibited
                    behavior includes, but is not limited to:
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Sharing,   uploading,   or   displaying   nudity,   pornography,   explicit
                            content,  or other offensive material.</li>
                        <li>Harassing, threatening, or bullying others.</li>
                        <li>Impersonating another person or creating fake profiles.</li>
                        <li>Sharing personal or financial information with others, such as bank
                            details, passwords, or PINs.</li>
                        <li>Promoting illegal activities, spam, or harmful information.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Zero Tolerance for Exploitation
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover has a zero-tolerance policy toward exploitation or blackmail. Any
                    attempt to blackmail or extort users will lead to immediate account suspension
                    and reporting to law enforcement.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.3. Ownership and Use of Content
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Users retain ownership of their posted content but grant Swipe Lover a limited
                    license to display, distribute, and use content for platform operation. Users are
                    solely responsible for their content and any potential legal repercussions from
                    third-party claims.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Subscription Plans, Coins, and In-App Purchases
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. Subscription Plans
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe Lover offers three plans: Free, Plus, and Pro, each with different levels
                            of access to features. The specifics of these plans, including benefits and costs,
                            are detailed in the App.</li>
                        <li>Subscription plans are non-transferable and provide users access to additional
                            features such as live streaming and random chat functionality.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Coins and Virtual Purchases
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Users   may   purchase   “coins”   to   access   various   features,   including   direct
                            messaging and commenting on live streams. Coins are valid for six months
                            from purchase and will automatically expire thereafter.</li>
                        <li>Coins and virtual goods cannot be exchanged for cash, refunded, or transferred
                            between users.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.3. Refund Policy
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    All purchases, including subscriptions and coins, are final and non-refundable
                    under any circumstances, including lack of live streamers or available users to
                    chat with. This policy applies regardless of service interruptions, deletion, or
                    suspension of the App.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.4. Modifications to Pricing and Features
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   reserves   the   right   to   change   subscription   fees   or   virtual   item
                    prices.   Changes   will   be   notified   to   users,   but   will   not   affect   existing
                    subscription fees until the next billing cycle.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Privacy and Data Collection
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. Data Collection and Usage
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe   Lover   collects   user   data,   including   real-time   images,   government   ID
                            details, and mobile numbers, email address, etc., to improve security and user
                            experience. Data is handled per the Privacy Policy.</li>
                        <li>Users   acknowledge   that   data   may   be   processed   and   stored   by   third-party
                            providers to facilitate verification or premium features.</li>
                    </ul>
                </Box>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2. Data Retention Policy
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Streaming data is retained for 30 days. User accounts inactive for a year may be
                    deleted. Users may request account deletion or data removal through the App’s
                    settings.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.3.  Security of Personal Data
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover employs encryption and industry-standard measures to secure user
                    data,   but   cannot   guarantee   absolute   security.   Users   agree   to   these   risks   and
                    waive claims against Swipe Lover for data breaches outside of our control.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. User Safety and Responsibilities
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Access and Update Your Information
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   encourages   safe   interactions,   advising   users   to   avoid   sharing
                    personal   or   financial   information   with   others.   Users   are   advised   to   arrange
                    initial meetings in public locations and follow general safety guidelines.
                </Text>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. User Responsibility for Interactions
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users   assume   full   responsibility   for   all   interactions   made   on   or   through   the
                    App.   Swipe   Lover   disclaims   any   liability   for   harm,   injuries,   or   damages
                    resulting from user interactions, both online and offline.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.3. Blackmail, Harassment, and Extortion
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   is   committed   to   preventing   harassment   and   blackmail.   Users
                    should   report   any   suspicious   or   threatening   behavior.   Violators   will   face
                    account suspension, and severe cases will be referred to law enforcement.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Intellectual Property Rights
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. Swipe Lover Intellectual Property
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover retains ownership of its brand, logos, trademarks, and proprietary
                    technology   used   in   the   App.   Users   may   not   use,   copy,   or   distribute   these
                    materials without written permission.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2 User Content Rights and Licensing
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    By posting content on the App, users grant Swipe Lover a worldwide, royalty-
                    free license to display and use their content for the operation of the platform.
                    This license allows Swipe Lover to moderate and share content but does not
                    transfer ownership.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.3 Third-Party Copyrights
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users are responsible for ensuring that content they share does not infringe on
                    third-party intellectual property rights. Any content found violating copyright or
                    trademark laws may be removed, and repeat offenders may face suspension.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Disclaimer of Warranties and Limitation of Liability
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.1. No Guarantee of Results
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   provides   no   guarantee   or   warranty   that   the   App   will   lead   to
                    successful   matches,   relationships,   or   social   interactions.   Users   agree   that
                    outcomes are beyond our control.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.2. User Interaction Disclaimer
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   is   not   responsible   for   user   conduct   or   interactions   within   or
                    outside the App. Any disputes arising from user interactions are solely between
                    the parties involved.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.3. Limitation of Liability
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    To the fullest extent permitted by law, Swipe Lover disclaims liability for:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Direct, indirect, or incidental damages resulting from user interactions.</li>
                        <li>Data breaches, unauthorized access, or loss of user data.</li>
                        <li>System disruptions or technical failures affecting App functionality.</li>
                    </ul>
                </Box>

                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.4. AI and Third-Party Limitations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   uses   AI   and   third-party   services   for   content   moderation   and
                    verification but disclaims responsibility for any errors, omissions, or limitations
                    in these systems.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    9. Third-Party Services, Advertising, and Endorsements
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.1. Influencer and Marketing Disclaimers
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   may   partner   with   influencers   for   promotions,   but   is   not
                    responsible   for   misleading   claims   made   by   third   parties.   Users   should   verify
                    information from official Swipe Lover channels only.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.2. Third-Party Verification Services
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover uses third-party providers for ID verification. Swipe Lover is not
                    liable for verification errors or delays caused by third-party providers.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.3. Advertisements and Promotions
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    The App may display advertisements provided by third parties. Swipe Lover
                    does   not   endorse   these   products   and   is   not   liable   for   third-party   goods   or
                    services.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    10. Dispute Resolution, Governing Law, and Jurisdiction
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.1. Governing Law
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    These Terms are governed by and construed under the laws of India.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.2. Jurisdiction
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    In case of any dispute, claim, or controversy arising from or related to these
                    Terms, the courts of Bikaner, Rajasthan, India shall have exclusive jurisdiction.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.3. Dispute Resolution
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users agree to attempt informal resolution by contacting Swipe Lover before
                    seeking   formal   legal   recourse.   Both   parties   will   aim   for   amicable   settlement
                    within a reasonable period.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.4. Arbitration Agreement (Optional)
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users may opt  for arbitration if agreed upon by both parties. The  arbitration
                    shall   take   place   in  Bikaner,   Rajasthan,   India,   and   the   Indian   Arbitration   and
                    Conciliation Act, 1996, will govern proceedings.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    11. Miscellaneous
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.1. Severability
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If any part of these Terms is held to be invalid or unenforceable, the remaining
                    portions shall continue to be in effect.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.2. Waiver
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Failure to enforce any provision of these Terms shall not constitute a waiver of
                    that right.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.3. Entire Agreement
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    These   Terms,   along   with   our   Privacy   Policy   and   Community   Guidelines,
                    constitute   the   entire   agreement   between   Swipe   Lover   and   users,   superseding
                    any prior agreements or communications.
                </Text>

                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    <b>By   using   Swipe   Lover,   you   confirm   that   you   have   read,   understood,   and
                        accepted   these   Terms. </b> If   you   disagree   with   any   part   of   these   Terms,   please
                    discontinue use of the App immediately.
                </Text>

            </Box>

        </>
    );
};

export default TermsOfUse;