import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL } from '../../utils/fonts/fonts';

const CopyRightPolicy = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Copyright & IP Policy
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>

                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Ownership of Swipe Lover Content
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    All   content   provided   by   Swipe   Lover,   including   but   not   limited   to   logos,
                    trademarks, design elements, graphics, text, and proprietary technology, is the
                    intellectual   property   of   Swipe   Lover   or   its   licensors.   Unauthorized   use,
                    reproduction, or distribution of this content is strictly prohibited.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. User-Generated Content and License
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>By posting content on Swipe Lover, including profile photos, messages, and
                            live stream interactions, you grant Swipe Lover a non-exclusive, royalty-free,
                            worldwide license to use, display, reproduce, and distribute your content for
                            the operation and promotion of the platform.</li>
                        <li>You retain ownership of your content but are responsible for ensuring it does
                            not infringe on third-party intellectual property rights.</li>
                    </ul>
                </Box>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Prohibited Content and Copyright Infringement
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Users   may   not   upload   or   share   copyrighted,   trademarked,   or   otherwise
                            protected content without permission from the rights holder. This includes
                            images, music, videos, and other media.</li>
                        <li>Content that violates this policy may be removed, and repeat infringers may
                            face account suspension or termination.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Reporting Copyright and IP Violations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If you believe your copyright or IP rights have been infringed on Swipe Lover,
                    please   submit   a   written   notice   to  support@swipelover.com   or
                    founder@digitalbikana.com with the following information:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>A description of the copyrighted work or IP that you claim has been
                            infringed.</li>
                        <li>A   description   of   the   infringing   content   and   its   location   on   Swipe
                            Lover.</li>
                        <li>Your contact information, including name, email, and physical address.</li>
                        <li>A statement that you have a good-faith belief that the use of the
                            copyrighted content is unauthorized.</li>
                        <li>A statement, under penalty of perjury, that the information in your
                            notice   is   accurate   and   that   you   are   the   copyright/IP   owner   or
                            authorized to act on behalf of the owner.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Counter-Notification Process
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If your content was removed due to a copyright or IP claim, and you believe the
                    claim   was   made   in   error,   you   may   submit   a   counter-notification   to
                    support@swipelover.com   or   founder@digitalbikana.com.   Include   details   about the removed content, your contact information, and a statement, under penalty of
                    perjury, that you have a good-faith belief the content was removed in error.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Repeat Infringer Policy
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   has   a   policy   for   terminating   accounts   of   users   who   are   repeat
                    infringers. Users who repeatedly violate this Copyright and IP Policy will face
                    account suspension or permanent termination.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Changes to this Policy
                </Text>
                <Text sx={text3Size} mb={'10px'}>

                    Swipe Lover may update this Copyright and IP Policy to reflect changes in our
                    practices or legal obligations. Continued use of the platform after updates signifies
                    acceptance of the revised policy.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    Contact Information
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    For   questions   regarding   this   Copyright   and   IP   Policy,   please   contact   us   at
                    support@swipelover.com or founder@digitalbikana.com.
                </Text>
            </Box>

        </>
    );
};

export default CopyRightPolicy;