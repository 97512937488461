import React from 'react';
import Phone from '../../utils/images/Live.png';
import Rectangle from '../../utils/images/Rectangle1.png';
import {
    Box,
    Flex,
    Image,
    useBreakpointValue,
    Text,
} from '@chakra-ui/react';
import {
    BODY_MEDIUM,
    HEADLINE_SMALL,
    HEADLINE_XXSMALL,
    LABEL_LARGE,
} from '../../utils/fonts/fonts';

const Features = () => {
    const imageSize = useBreakpointValue({
        base: { width: '121.606px', height: '262.292px' },
        md: { width: '162.141px', height: '349.722px' },
        lg: { width: '279.554px', height: '602.969px' },
    });

    const textSize = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text2Size = useBreakpointValue({
        base: LABEL_LARGE,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = 'dark.onSurface';

    return (
        <Box>
            <Box
                color={textColor}
                rounded="24"
                bgPos={{ base: 'top', md: 'top', lg: 'unset' }}
                bgImage={{ base: Rectangle, md: Rectangle, xl: Rectangle }}
                bgRepeat={'no-repeat'}
                bgSize="100% 90%"
                mt={6}
                px={{ base: '10px', md: '40px', lg: '28px' }}
            >
                <Flex
                    direction={{ base: 'column', lg: 'row' }}
                    justify={{ base: 'center', lg: 'space-around' }}
                    align="center"
                >
                    <Box
                        pt={{ base: '24px', md: '32px', lg: '82.53px' }}
                        px={{ base: '12px', md: '16px', lg: '0px' }}
                        width={{ base: '200', md: '300', lg: '550px' }}
                        textAlign={{ base: 'left', lg: 'left' }}
                        alignSelf={'start'}

                    >
                        <Text
                            sx={textSize}
                        >
                            Safe and Easy Sign-Up
                        </Text>

                        <Box
                            sx={text2Size}
                            ml={{ base: '0px', lg: '6px' }}
                        >
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>

                                <li>
                                    Sign up via mobile or social accounts (Facebook, Google, Apple).
                                </li>
                                <li>
                                    Verified profiles with AI-powered facial recognition for safety.
                                </li>
                                <li>
                                    Personalized profiles with detailed info and photos.
                                </li>
                            </ul>
                        </Box>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Swipe to Connect
                        </Text>

                        <Box
                            sx={text2Size}
                            ml={{ base: '0px', lg: '6px' }}
                        >
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>

                                <li>
                                    Swipe right to like, left to pass.
                                </li>
                                <li>
                                    Smart matching based on location and interests.
                                </li>
                                <li>
                                    Compete on leaderboards by earning hearts!
                                </li>
                            </ul>
                        </Box>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Chat and Live Streams
                        </Text>

                        <Box
                            sx={text2Size}
                            ml={{ base: '0px', lg: '6px' }}
                        >
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>

                                <li>
                                    Direct chats and coin-based random chat for surprises.
                                </li>
                                <li>
                                    Live streaming with real-time interactions and auto-captioning.
                                </li>
                                <li>
                                    Safe and moderated chat environment.
                                </li>
                            </ul>
                        </Box>
                        <Text
                            sx={textSize}
                            pt={{ base: '16px', lg: '24px' }}
                        >
                            Coins and Rewards
                        </Text>

                        <Box
                            sx={text2Size}
                            ml={{ base: '0px', lg: '6px' }}
                        >
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>
                                    Earn coins by engaging and use them for special features.
                                </li>
                                <li>
                                    Rank on leaderboards and get rewarded for activity.
                                </li>
                            </ul>
                        </Box>
                    </Box>
                    <Box
                        pt={{ base: '24px', md: '32px', lg: '82.53px' }}
                    >
                        <Image
                            sx={imageSize}
                            src={Phone}
                            alt='Swipe Lover'
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default Features;
