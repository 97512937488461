import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const UserAgreement = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    User Verification Agreement
                </Text>
            </Box>
            <Box
                px={{ base: '20px', md: '30px', lg: '60px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 22, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Welcome to <b>Swipe Lover!</b>Swipe Lover takes user safety and authenticity seriously. This
                    User   Verification   Agreement   (the   “Agreement”)   outlines   the   verification   process,   data
                    collection methods, and user obligations related to account verification on Swipe Lover. By
                    using Swipe Lover, you agree to participate in our verification processes and adhere to the
                    terms outlined in this Agreement.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Purpose of Verification
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover requires users to undergo verification to create a safe, authentic, and trusted
                    environment for everyone. Verification helps ensure that users are who they claim to be,
                    preventing fraudulent accounts, impersonation, and underage users from accessing the
                    platform.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Verification Requirements
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    To register and use Swipe Lover, users must complete the following verification steps:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.1. Mobile Number Verification
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Users must provide a valid mobile number during registration. This number will be
                            used to verify account ownership and will not be displayed publicly.</li>
                        <li>Swipe Lover may send a one-time password (OTP) to confirm the mobile number’s
                            authenticity.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.2. Real-Time Image and Video Verification
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Users   must   capture   a   real-time   image   or   video   during   registration   to   verify   their
                            identity and confirm that they meet the age requirements.</li>
                        <li>Swipe Lover may ask users to perform specific actions (e.g., moving the head in
                            different directions) to ensure that the image or video is captured live.</li>
                        <li>This real-time image and video data will not be publicly visible and will be stored
                            securely in accordance with our Privacy Policy.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.3. Government ID Verification (Not Mandatory / Used for increasing verification
                    score)
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Users may be required to submit a valid government-issued ID to verify their identity
                            and age. Acceptable forms of ID may vary by country and will be specified within the
                            app.</li>
                        <li>Swipe Lover uses third-party verification providers to match the ID photo with the
                            real-time image or video. The third-party provider may verify that the person in the
                            ID is the same as the person in the real-time capture, but they will not validate the
                            ID’s authenticity or compare name details.</li>
                        <li>Government ID verification may become mandatory for accessing certain features,
                            such as live streaming.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.4. Social Media Link Verification (Not Mandatory / Used for increasing verification
                    score).
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    To enhance your profile authenticity and increase your verification score, Swipe Lover
                    allows users to link their social media accounts (e.g., Facebook, Instagram, LinkedIn,
                    etc.) to their profile. While this step is optional, it provides the following benefits:
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Enhanced Profile Trustworthiness:</b>   Linking   your   social   media   accounts   helps
                            demonstrate that your profile is authentic and managed by a real person.</li>
                        <li> <b>Higher Verification Score:</b>   Users   who   complete   social   media   link   verification
                            receive additional points toward their overall profile verification score. This score is
                            visible on your profile to indicate its trust level to others.</li>

                    </ul>
                </Box>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>Verification Process:</b>
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>To link a social media account, users will be prompted to log in to the respective
                            platform through a secure interface provided by Swipe Lover.</li>
                        <li>Swipe   Lover   does   not   store   your   social   media   login   credentials   but   uses   tokens
                            provided by the platform to confirm the connection.</li>

                    </ul>
                </Box>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>Privacy and Usage:</b>
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Your linked social media accounts will not be publicly visible unless you choose to
                            display them.</li>
                        <li>Swipe   Lover   will   not   post   on   your   social   media   accounts   without   your   explicit
                            permission.</li>

                    </ul>
                </Box>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    By   linking   a   social   media   account,   you   acknowledge   and   consent   to   Swipe   Lover
                    accessing limited public data (e.g., your name and profile picture) to verify your identity.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    2.5. Email ID verification.
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover requires users to provide a valid email address during account registration
                    or later in the account setup process. Email ID verification serves the following purposes:
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Account Security:</b> Verifying your email helps secure your account by providing a
                            secondary contact method for account recovery or notifications.</li>
                        <li> <b>Enhanced Verification Score:</b>   Completing   email   verification   contributes   to   your
                            overall profile verification score, demonstrating additional proof of authenticity.</li>

                    </ul>
                </Box>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>Verification Process:</b>
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>After   entering   your   email   address,   Swipe   Lover   will   send   a   verification   email
                            containing a unique link or code.</li>
                        <li>Users must click the link or enter the code in the app to confirm the email address.</li>

                    </ul>
                </Box>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>Communication and Privacy:</b>
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Verified   email   addresses   will   be   used   to   send   important   account-related
                            communications,   such   as   password   reset   instructions,   account   status   updates,   and
                            feature announcements.</li>
                        <li>Swipe   Lover   will   not   share   your   email   address   with   third   parties   for   marketing
                            purposes without your explicit consent.</li>

                    </ul>
                </Box>
                <Text sx={text3Size} mb={'10px'} mt={'10px'}>
                    <b>Mandatory for Certain Actions:</b>
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Email   verification   may   be   required   for   specific   app   features,   such   as   purchasing
                            subscriptions, redeeming promotional offers, or accessing premium functionalities.</li>

                    </ul>
                </Box>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    By   verifying   your   email   address,   you   acknowledge   and   consent   to   its   use   for
                    communication and verification purposes as outlined in Swipe Lover’s Privacy Policy.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. Verification Data Collection and Storage
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Data Collected
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>As   part   of   the   verification   process,   Swipe   Lover   collects   data,   including   mobile
                            numbers,   Email   Address,   real-time   images   or   videos,   and   government   ID
                            information.</li>
                        <li>This data is stored securely and used solely for verification, safety, and compliance
                            purposes.</li>
                        <li>By agreeing to the User Verification Agreement, you consent to your email address
                            and   mobile   number   being   shared   with   third-party   companies   for   the   purpose   of
                            marketing and advertising Swipe Lover’s services, promotions, and special offers.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Data Retention
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Verification data, such as images, videos, and ID information, is retained as long as
                            necessary to fulfill verification purposes, address safety concerns, and comply with
                            legal obligations. Verification data is securely deleted when no longer needed.</li>
                        <li>For live streaming data, Swipe Lover retains content for a maximum of 30 days. After
                            this period, the content is permanently deleted unless retention is required for legal or
                            safety purposes.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.3. Third-Party Providers and Data Security
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Swipe   Lover   partners   with   third-party   providers   for   government   ID   verification.
                            These   providers   comply   with   data   protection   regulations   and   maintain   strict
                            confidentiality and security standards.</li>
                        <li>By agreeing to this Agreement, you consent to the processing of your verification
                            data by third-party providers, solely for identity confirmation.</li>
                    </ul>
                </Box>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. Consent to Verification Process
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    By creating an account, you consent to the following:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. Consent to Data Collection and Processing
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You authorize  Swipe  Lover to collect, store, and process your verification data  as
                    outlined in this Agreement. You agree to the storage of your real-time image, video,
                    mobile number, email address, and government ID data for the purpose of verification
                    and safety.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Consent to Real-Time Image and Video Capture
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>You   consent   to   capturing   a   real-time   image   or   video   during   registration.   This
                            verification   step   ensures   that   you   are   a   real   person   and   meet   the   minimum   age
                            requirement (18 years and above).</li>
                        <li>The real-time image and video captured during verification will be compared to any
                            uploaded profile photo to confirm accuracy.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.3. Consent to Government ID Verification
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>By submitting your government-issued ID, you consent to identity verification by a
                            third-party provider, which may involve comparing the ID photo with the real-time
                            image or video.</li>
                        <li>You understand that Swipe Lover will only verify the likeness of your photo and will
                            not authenticate the validity of the ID or verify name details.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.4. Age and Identity Consent
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You confirm that you are 18 years or older and have provided accurate information
                    about your age and identity. Swipe Lover prohibits users under 18 or minor from using
                    the platform, and false representation of age is grounds for account suspension.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Prohibited Activities
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    The following activities are strictly prohibited and may lead to account suspension or legal
                    action:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. Falsification of Verification Data
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Attempting   to   falsify   verification   data,   including   submitting   fake   IDs,   pre-recorded
                    videos, or edited images, is prohibited. Accounts found to be  falsifying data  will  be
                    permanently banned.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2. Misuse of Another Person’s Identity
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users   may   not   impersonate   others   or   use   another   person’s   ID   for   verification.
                    Impersonation is a serious offense and may result in immediate account termination.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.3. Circumventing Verification Requirements
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Circumventing the verification process by any means, such as creating multiple accounts
                    to avoid verification, is strictly prohibited. Such actions will lead to account suspension
                    and potential legal consequences.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Consequences of Non-Compliance
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Failure to comply with this Agreement and complete the verification requirements may
                    result in the following actions:
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Account Suspension or Termination
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Accounts   failing   to   meet   verification   standards,   refusing   verification   requests,   or
                    providing falsified information will face suspension or termination.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. Restricted Access to Features
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Certain   features,   such   as   live   streaming   and   premium   interactions,   may   require
                    government ID verification. Failure to provide valid ID verification will restrict access to
                    these features.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.3. Legal Action and Reporting to Authorities
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover reserves the right to report fraudulent activities, such as identity theft or
                    misrepresentation, to relevant authorities for further investigation and action.
                </Text>

                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. User Rights and Data Access
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. Accessing and Updating Verification Data
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users may review their verification data by reaching out to Swipe Lover’s support team.
                    If   inaccuracies   are   identified,   you   can   request   updates,   and   Swipe   Lover   will   make
                    reasonable efforts to address the issue. However, resolution is not guaranteed.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2 Requesting Data Deletion
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users have the right to request the deletion of their verification data. However, please
                    note   that   certain   data   may   be   retained   for   legal   compliance   and   safety   purposes,
                    especially in cases of suspected fraud or misuse.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.3. Right to Withdraw Consent
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You may withdraw consent for data collection or verification at any time by deactivating
                    your account. Note that withdrawing consent will result in account termination and the
                    loss of access to Swipe Lover’s features.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Limitation of Liability
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.1. Verification Accuracy
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    While   Swipe   Lover   and   its   verification   providers   strive   for   accuracy   in   identity
                    verification, the system may not detect all attempts at misrepresentation. Swipe Lover is
                    not   liable   for   inaccuracies   in   verification   results,   including   errors   by   third-party
                    providers.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.2. No Guarantee of User Conduct
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Verification does not guarantee that all users are trustworthy. You are responsible for
                    exercising caution and following safety guidelines when interacting with others on Swipe
                    Lover.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.3. Limitations of AI-Based Verification
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    AI technology is used for certain aspects of verification but is not infallible. Swipe Lover
                    disclaims   responsibility   for   any   errors   or   limitations   associated   with   AI-based
                    verification.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    9. Changes to This Agreement
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.1. Updates to the Agreement
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover may update this User Verification Agreement periodically to reflect new
                    practices,   technologies,   or   legal   requirements.   Users   will   be   notified   of   significant
                    changes via in-app notifications, email or can check for updates in App or website.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.2. Effective Date
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    The effective date of this Agreement is noted at the top of the document. Continued use
                    of Swipe Lover following an update indicates acceptance of the revised Agreement.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    10. Contact Information
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.1. Questions and Support
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If   you   have   questions   or   concerns   about   this   User   Verification   Agreement,   please
                    contact us at support@swipelover.com or founder@digitalbikana.com.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.2. Grievance Redressal Officer
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover has appointed a Grievance Redressal Officer to address any complaints or
                    disputes   related   to   verification   practices.   Contact   information   for   the   Grievance
                    Redressal Officer is available on our website.
                </Text>

                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    <b>By completing the verification process on Swipe Lover, you confirm that you have read,
                        understood, and agree to abide by this User Verification Agreement.</b>
                </Text>

            </Box>

        </>
    );
};

export default UserAgreement;