import { extendTheme } from '@chakra-ui/react';

const customColors = {
    "light": {
        "primary": "#485D92",
        "surfaceTint": "rgba(12, 87, 202, 0.08)",
        "onPrimary": "#FFFFFF",
        "primaryContainer": "#DAE2FF",
        "onPrimaryContainer": "#001946",
        "secondary": "#904A47",
        "onSecondary": "#FFFFFF",
        "secondaryContainer": "#FFDAD7",
        "onSecondaryContainer": "#3B080A",
        "tertiary": "#436833",
        "onTertiary": "#FFFFFF",
        "tertiaryContainer": "#C4EFAC",
        "onTertiaryContainer": "#052100",
        "error": "#BA1A1A",
        "onError": "#FFFFFF",
        "errorContainer": "#FFDAD6",
        "onErrorContainer": "#410002",
        "background": "#FAF8FF",
        "onBackground": "#1A1B21",
        "surface": "#FAF8FF",
        "onSurface": "#1A1B21",
        "surfaceVariant": "#E1E2EC",
        "onSurfaceVariant": "#44464F",
        "outline": "#757780",
        "outlineVariant": "#C5C6D0",
        "shadow": "#000000",
        "scrim": "#000000",
        "inverseSurface": "#2F3036",
        "inverseOnSurface": "#F1F0F7",
        "inversePrimary": "#B1C5FF",
        "primaryFixed": "#DAE2FF",
        "onPrimaryFixed": "#001946",
        "primaryFixedDim": "#B1C5FF",
        "onPrimaryFixedVariant": "#2F4578",
        "secondaryFixed": "#FFDAD7",
        "onSecondaryFixed": "#3B080A",
        "secondaryFixedDim": "#FFB3AF",
        "onSecondaryFixedVariant": "#733331",
        "tertiaryFixed": "#C4EFAC",
        "onTertiaryFixed": "#052100",
        "tertiaryFixedDim": "#A8D292",
        "onTertiaryFixedVariant": "#2C4F1D",
        "surfaceDim": "#DAD9E0",
        "surfaceBright": "#FAF8FF",
        "surfaceContainerLowest": "#FFFFFF",
        "surfaceContainerLow": "#F4F3FA",
        "surfaceContainer": "#EEEDF4",
        "surfaceContainerHigh": "#E8E7EF",
        "surfaceContainerHighest": "#E2E2E9"
    },
    "dark": {
        "primary": "#B1C5FF",
        "surfaceTint": "rgba(177, 197, 255, 0.08)",
        "onPrimary": "#162E60",
        "primaryContainer": "#2F4578",
        "onPrimaryContainer": "#DAE2FF",
        "secondary": "#FFB3AF",
        "onSecondary": "#571D1D",
        "secondaryContainer": "#733331",
        "onSecondaryContainer": "#FFDAD7",
        "tertiary": "#A8D292",
        "onTertiary": "#153808",
        "tertiaryContainer": "#2C4F1D",
        "onTertiaryContainer": "#C4EFAC",
        "error": "#FFB4AB",
        "onError": "#690005",
        "errorContainer": "#93000A",
        "onErrorContainer": "#FFDAD6",
        "background": "#121318",
        "onBackground": "#E2E2E9",
        "surface": "#121318",
        "onSurface": "#E2E2E9",
        "surfaceVariant": "#44464F",
        "onSurfaceVariant": "#C5C6D0",
        "outline": "#8F9099",
        "outlineVariant": "#44464F",
        "shadow": "#000000",
        "scrim": "#000000",
        "inverseSurface": "#E2E2E9",
        "inverseOnSurface": "#2F3036",
        "inversePrimary": "#485D92",
        "primaryFixed": "#DAE2FF",
        "onPrimaryFixed": "#001946",
        "primaryFixedDim": "#B1C5FF",
        "onPrimaryFixedVariant": "#2F4578",
        "secondaryFixed": "#FFDAD7",
        "onSecondaryFixed": "#3B080A",
        "secondaryFixedDim": "#FFB3AF",
        "onSecondaryFixedVariant": "#733331",
        "tertiaryFixed": "#C4EFAC",
        "onTertiaryFixed": "#052100",
        "tertiaryFixedDim": "#A8D292",
        "onTertiaryFixedVariant": "#2C4F1D",
        "surfaceDim": "#121318",
        "surfaceBright": "#38393F",
        "surfaceContainerLowest": "#0C0E13",
        "surfaceContainerLow": "#1A1B21",
        "surfaceContainer": "#1D1F27",
        "surfaceContainerHigh": "#282A2F",
        "surfaceContainerHighest": "#33343A"
    },
};
  
const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "80em", // 1280px
  xl: "120em", // 1920px
};

const theme = extendTheme({
  colors:customColors,
  breakpoints:breakpoints
});

export default theme;
