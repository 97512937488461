import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LOGO from '../../utils/images/LogoFooter.svg';
import LOGODARK from '../../utils/images/LogoFooterDark.svg';
import {
    Box,
    Flex,
    Link,
    useColorModeValue,
    Image,
    useBreakpointValue,
    Text,
    Icon,
} from '@chakra-ui/react';
import {
    BODY_MEDIUM,
    BODY_SMALL,
} from '../../utils/fonts/fonts';
import { RiInstagramFill } from 'react-icons/ri';
import { FaFacebook, FaLinkedin, FaPinterest, FaTiktok, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    const imageSize = {
        width: '90px', height: '72px'
    };
    const iconSize = {
        width: '30px', height: '30px'
    };

    const textSize = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_SMALL,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    const year = new Date()

    const socialIcons = [
        { path: 'https://www.instagram.com', icon: <RiInstagramFill /> },
        { path: 'https://www.facebook.com', icon: <FaFacebook /> },
        { path: 'https://www.linkedin.com', icon: <FaLinkedin /> },
        { path: 'https://www.pinterest.com', icon: <FaPinterest /> },
        { path: 'https://www.tiktok.com', icon: <FaTiktok /> },
        { path: 'https://www.youtube.com', icon: <FaYoutube /> }
    ]
    const links = [
        [
            { path: '/user-agreement', label: 'User Verification Agreement' },
            { path: '/guidelines', label: 'Community Guidelines', },
            { path: '/sub-agreement', label: 'Subscription Agreement' }
        ],
        [
            { path: '/copyright-policy', label: 'Copyright & IP Policy' },
            { path: '/security-policy', label: 'Safety & Security Policy' },
            { path: '/privacy-policy', label: 'Privacy Policy' }
        ],
        [
            { path: '/ad-disclaimer', label: 'Advertising Disclaimer' },
            { path: '/ad-policy', label: 'Advertising Policy' },
            { path: '/terms', label: 'Terms Of Use', }
        ]
    ]

    return (
        <>
            <Box rounded="24"
                p={{ base: 6, md: 8, lg: 10 }}
                bg={useColorModeValue('light.surfaceContainer', 'dark.surfaceContainer')}
                mt={{base:8,md:12,lg:16}}
    >
                <Flex justify={{ base: 'center', lg: 'space-between' }}
                    direction={{ base: 'column', lg: 'row' }}
                >
                    <Box>
                        <Box justifySelf={{ base: 'center', lg: 'start' }}>
                            <Link
                                as={RouterLink}
                                to={'/'}
                            >
                                <Image
                                    sx={imageSize}
                                    src={useColorModeValue(LOGO, LOGODARK)}
                                    alt='Swipe Lover Logo'
                                />
                            </Link>
                        </Box>
                        <Box py={4} display={'flex'} gap={'8px'} justifySelf={{ base: 'center', lg: 'start' }}>
                            {socialIcons.map((icons) =>
                                <Link key={icons.path}
                                href={icons.path} isExternal
                                    _hover={{ opacity: 0.8 }}
                                    alt={'Social-icon'}
                                >
                                    <Icon sx={iconSize} color={textColor} >{icons.icon} </Icon>
                                </Link>
                            )}
                        </Box>
                        <Box textAlign={{ base: 'center', lg: 'start' }} mb={{ base: 6, lg: 0 }} w={{base:'100%', lg:'70%'}}>
                            <Text

                                sx={textSize}
                                color={textColor}
                            >
                                &copy; {year.getFullYear()} Swipe Lover LLC, All Rights Reserved.
                            </Text>
                        </Box>
                    </Box>
                   <Box>
                        <Flex  flexWrap={'wrap'} justify={{ base: 'center', md: 'space-between' }} gap={8} direction={{ base: 'column', md: 'row' }} >
                            {links.map((link, index) =>
                                <Box key={index} >
                                    {link.map((data) =>
                                        <Flex justify={{base:'center', md: 'start'}} textAlign={{ base:'center', md: 'start' }}>
                                            <Box key={data.path} mb={4}>
                                                <Link
                                                    as={RouterLink}
                                                    to={data.path}
                                                    sx={textSize}
                                                    bg="transparent"
                                                    _focus={{ outline: '0px solid', outlineColor: 'transparent', borderRadius: '0px' }}
                                                    _hover={{ textDecoration: 'none', opacity: 0.8 }}
                                                    color={textColor}
                                                >
                                                    {data.label}
                                                </Link>
                                            </Box>
                                        </Flex>
                                    )}
                                </Box>
                            )}
                        </Flex>
                    </Box>
                </Flex>

            </Box>
        </>
    );
};

export default Footer;
