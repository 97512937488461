import { Box, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BODY_MEDIUM, BODY_SMALL, DISPLAY_SMALL, HEADLINE_LARGE, HEADLINE_SMALL, HEADLINE_XSMALL, HEADLINE_XXSMALL } from '../../utils/fonts/fonts';

const SubscriptionAgreement = () => {
    const textSize = useBreakpointValue({
        base: HEADLINE_SMALL,
        md: HEADLINE_LARGE,
        lg: DISPLAY_SMALL,
    });
    const text2Size = useBreakpointValue({
        base: HEADLINE_XXSMALL,
        md: HEADLINE_XSMALL,
        lg: HEADLINE_XSMALL,
    });
    const text4Size = useBreakpointValue({
        base: HEADLINE_XSMALL,
        md: HEADLINE_SMALL,
        lg: HEADLINE_SMALL,
    });

    const text3Size = useBreakpointValue({
        base: BODY_SMALL,
        md: BODY_MEDIUM,
        lg: BODY_MEDIUM,
    });

    const textColor = useColorModeValue('light.onSurface', 'dark.onSurface');
    return (
        <>
            <Box
                rounded="24"
                mt={10}
                color={textColor}
                borderBottomRightRadius={'3xl'}
                bgGradient={'linear(to-r,#0042A0,#930017)'}
                px={{ base: '20px', md: '20px', lg: '26px' }}
                py={{ base: '16px', lg: '40px' }}
                mb={20}
            >
                <Text
                    color={'dark.onSurface'}
                    sx={textSize}
                    textAlign={'center'}
                >
                    Subscription Agreement
                </Text>
            </Box>
            <Box
                px={{ base: '10px', md: '20px', lg: '40px' }}
                textAlign="justify"
                color={textColor}
            >
                <Text sx={text3Size} mb={'10px'}>
                    <i>Last Updated: November 4, 2024</i>
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Welcome to Swipe Lover! This Subscription Agreement (the “Agreement”) sets forth
                    the terms and conditions governing your subscription to Swipe Lover’s premium
                    features and services. By purchasing a subscription or in-app coins on Swipe Lover,
                    you agree to be bound by this Agreement, as well as our Terms of Service and
                    Privacy Policy.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    1. Overview of Subscription Plans
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover offers multiple subscription options to enhance your user experience.
                    Each plan provides varying levels of access to app features, including daily swipes,
                    messaging, live streaming, and random chat.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    1.1. Subscription Plans Available
                </Text>
                <Box
                    sx={text3Size}
                    mb={'10px'}
                >
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Free Plan:</b> Limited to 15 daily swipes, with no access to messaging, live
                            streaming, or random chat.</li>
                        <li><b>Swipe Lover Plus:</b> Offers 30 daily swipes, access to random chat with coin-
                            based messaging, and 15 free coins.</li>
                        <li><b>Swipe Lover Pro:</b> Includes 30 daily swipes, access to live streaming (coin-
                            based for comments), random chat (coin based for messaging), and 30 free
                            coins.</li>
                    </ul>
                </Box>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    1.2. Coin-Based Usage
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Coins are required for certain actions, such as sending messages, swiping
                    beyond daily limits, commenting on live streams, and viewing profiles. Coins
                    are purchased separately and are subject to additional terms outlined in this
                    Agreement.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'20px'}
                >
                    2. Subscription Terms and Payment
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.1. Subscription Period and Renewal
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Subscriptions are available on a recurring basis (e.g., monthly, quarterly, or
                    annually)   as   specified   at   the   time   of   purchase.   Your   subscription   will
                    automatically renew or not at the end of each period unless you cancel prior to
                    the renewal date.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.2. Payment Authorization
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    By purchasing a subscription, you authorize Swipe Lover to charge the payment
                    method associated with your account for the subscription fee, including any
                    applicable taxes. Charges will be processed on the renewal date unless the
                    subscription is canceled.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.3. No Refund Policy
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    All subscription purchases are final and non-refundable, even if you do not use
                    all the features provided under your subscription or if the app experiences low
                    user activity at any given time. This includes circumstances where there are
                    limited live streamers or active chat participants.
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    2.4. Price Changes
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Swipe Lover reserves the right to change subscription prices at any time. Price
                    changes   will   be   effective   upon   renewal   of   your   subscription.   Existing
                    subscribers will be notified of any price adjustments in advance or can check
                    about pricing within the app.
                </Text>
                <Text
                    sx={text4Size}
                    mb={'10px'}
                    mt={'25px'}
                >
                    3. In-App Purchases and Coins
                </Text>
                <Text
                    sx={text2Size}
                    mb={'10px'}
                    mt={'10px'}
                >
                    3.1. Purchasing Coins
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Coins are a virtual currency used to unlock additional features in the app,
                    including messaging, swiping beyond daily limits, and interacting with live
                    streams. Coins can be purchased as one-time transactions within the app.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.2. Expiration of Coins
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Purchased coins are valid for six months from the date of purchase. Any unused
                    coins will expire after this period and cannot be transferred, refunded, or
                    converted to cash.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.3. Usage of Coins
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Purchased coins are valid for six months from the date of purchase. Any unused
                    coins will expire after this period and cannot be transferred, refunded, or
                    converted to cash.
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Coin usage includes, but is not limited to:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><b>Direct Messaging:</b> 5 coins per message.</li>
                        <li><b>Extra Swipes:</b> 1 coin per swipe beyond the daily limit.</li>
                        <li><b>Live Stream Comments:</b> 1 coin per comment.</li>
                        <li><b>Profile View in Leaderboard:</b> 2 coins per profile view.</li>
                        <li><b>Sending Hearts:</b>1 coin per heart sent.</li>

                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    3.4. No Resale or Transfer of Coins
                </Text>
                <Text
                    sx={text3Size}
                    mb={'10px'}
                >
                    Coins are non-transferable and cannot be resold. Any attempt to sell or transfer
                    coins will result in account suspension or termination.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    4. User Responsibilities
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.1. Authorized Use
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Subscription features and coins are solely for personal, non-commercial use.
                    Users may not share, sublicense, or allow third parties to access subscription
                    features through their accounts.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.2. Compliance with Community Guidelines
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users must comply with all Swipe Lover Community Guidelines while using
                    subscription features. Violation of these guidelines may result in suspension or
                    termination of your account without a refund.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    4.3. Device Compatibility
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Users are responsible for ensuring their device is compatible with Swipe Lover.
                    We do not guarantee app functionality on all devices and are not liable for
                    performance issues arising from device incompatibility.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    5. Cancellation and Termination
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.1. Canceling a Subscription
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    You may cancel your subscription at any time through the account settings.
                    Cancellations will take effect at the end of the current billing period, and you
                    will retain access to premium features until then.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.2. Termination by Swipe Lover
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover reserves the right to suspend or terminate your subscription and
                    account for violation of the Terms of Service, Community Guidelines, or this
                    Agreement. Termination for cause will not entitle you to a refund.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    5.3. Effect of Termination
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Upon termination of your subscription, access to all premium features will end.
                    You will retain access to any remaining free features, but unused coins will be
                    forfeited.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    6. Changes to Subscription Features and Services
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.1. Modification of Features
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe   Lover   may   add,   modify,   or   remove   features   offered   under   each
                    subscription plan. Such changes are made to improve user experience and may
                    be implemented without prior notice. Continued use of the subscription plan
                    after modifications constitutes acceptance of the changes.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    6.2. No Guarantee of Availability
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover does not guarantee the availability of specific content, users, or
                    features   at   any   given   time.   Subscription   access   is   subject   to   the   app’s
                    operational and user dynamics, which may vary.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    7. Disclaimers and Limitation of Liability
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.1. No Warranty of Match Success
                </Text>

                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover does not guarantee successful matches or outcomes as a result of
                    using premium features. Users are solely responsible for their interactions and
                    engagement within the app.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.2 Limitation of Liability
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    To the fullest extent permitted by law, Swipe Lover disclaims liability for any
                    direct, indirect, incidental, or consequential damages resulting from:
                </Text>
                <Box sx={text3Size} mb={'10px'}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>Service interruptions or availability of premium features.</li>
                        <li>Technical issues affecting access to subscription content.</li>
                        <li>Third-party payment processing errors.</li>
                    </ul>
                </Box>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    7.3. AI and Verification Limitations
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover utilizes AI-based verification but disclaims liability for errors or
                    inaccuracies resulting from AI limitations. Users must exercise caution and
                    report any suspicious activity.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    8. Privacy and Data Handling
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.1. Data Collection for Subscription Use
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover collects and processes user data in accordance with our Privacy
                    Policy.   Subscription   and   in-app   purchases   require   data   such   as   payment
                    information, device identifiers, and usage activity to provide and improve our
                    services.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.2. Sharing with Third-Party Processors
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Payment   and   verification   services   are   processed   by   trusted   third-party
                    providers. By purchasing a subscription or coins, you consent to the secure
                    processing of your data by these third-party providers.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    8.3. Data Retention and Deletion
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Subscription data is retained as long as necessary to fulfill the service. Upon
                    termination, your personal data may be deleted or anonymized in compliance
                    with our retention policy, except where required by law.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    9. Dispute Resolution and Governing Law
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.1. Governing Law
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    This Agreement shall be governed by and construed in accordance with the
                    laws of India.
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.2. Dispute Resolution
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    In the event of a dispute arising from this Agreement, users agree to attempt
                    informal resolution by contacting Swipe Lover’s support team. If a resolution
                    cannot be reached, the dispute shall be resolved in the courts of [City, India].
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    9.3. Arbitration Option
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Where   applicable,   users   may   choose   to   resolve   disputes   through   binding
                    arbitration in accordance with the Indian Arbitration and Conciliation Act,
                    1996.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    10. Amendments to This Agreement
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    10.1. Right to Amend
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    Swipe Lover reserves the right to amend or update this Subscription Agreement
                    at any time. Users may be notified of any significant changes through in-app
                    notifications or email. Continued use of subscription services after amendments
                    constitutes acceptance of the revised Agreement.
                </Text>
                <Text sx={text4Size} mb={'10px'} mt={'25px'}>
                    11. Contact Information
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.1. Questions and Support
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    If you have questions about this Subscription Agreement or need assistance
                    with subscription services, please contact us at  support@swipelover.com  or
                    founder@digitalbikana.com
                </Text>
                <Text sx={text2Size} mb={'10px'} mt={'10px'}>
                    11.2. Grievance Redressal Officer
                </Text>
                <Text sx={text3Size} mb={'10px'}>
                    In compliance with Indian regulations, Swipe Lover has appointed a Grievance
                    Redressal   Officer   to   address   complaints   related   to   subscription   services.
                    Contact information for the Grievance Redressal Officer is available on our
                    website.
                </Text>
                <Text sx={text3Size} mb={'10px'} mt={'20px'}>
                    <b>By purchasing a subscription on Swipe Lover, you acknowledge that you have
                    read, understood, and agreed to the terms of this Subscription Agreement.</b>
                </Text>

            </Box>

        </>
    );
};

export default SubscriptionAgreement;